import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import statesAndLGAs from "../component/AllStateLga";
import { Link, useNavigate } from "react-router-dom";
import { useMyContext } from "../context/MyContext";
import { AiOutlineCloseCircle } from "react-icons/ai"; // Import the cancel icon

const SignUpForm3 = ({ regMail }) => {
  const [selectedState, setSelectedState] = useState("");
  const [selectedLGA, setSelectedLGA] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState("");
  const [religion, setReligion] = useState("");
  const [interests, setInterests] = useState([]);
  // const [errorText, setErrorText] = useState("");
  const { email, isLoading, setEmail, setIsLoading } = useMyContext();
  const [name, setName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve email from local storage
    const storedData = JSON.parse(localStorage.getItem("signupForm1State"));
    if (storedData && storedData.email) {
      setEmail(storedData.email);
    }
    if (storedData && storedData.fullName) {
      setName(storedData.fullName);
    }
  }, []);

  const selectedStateData = statesAndLGAs.find(
    (stateData) => stateData.state === selectedState
  );
  const lgas = selectedStateData ? selectedStateData.lgas : [];

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    setSelectedLGA(""); // Reset LGA when the state changes
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleInterestChange = (interest) => {
    // Check if the interest is already selected
    if (interests.includes(interest)) {
      // Deselect the interest
      setInterests(interests.filter((item) => item !== interest));
    } else if (interests.length < 3) {
      // Select the interest if less than 3 are already selected
      setInterests([...interests, `${interest}`]);
    } else {
      toast.error("You can only select up to three interests");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      // Make a post request to the specified endpoint
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register/3?email=${regMail}`,
        {
          Gender: gender,
          State: selectedState,
          LGA: selectedLGA,
          DOB: dob,
          Religion: religion,
          Interest: interests, // Pass interests directly as an array of strings
        }
      );

      const data = response.data;

      if (data.Access) {
        // Check for success and handle accordingly
        if (data.Uploaded) {
          toast.success("Details uploaded successfully");
          navigate(`/register4/${regMail}`);
        } else {
          toast.error("Error uploading details");
        }
      } else {
        toast.error(data.Error || "Unknown error");
      }
    } catch (error) {
      toast.error(error.response?.data?.Error || "Unknown error");
    } finally {
      setIsLoading(false); // Set loading state to false
      console.log(
        `Interests : ${interests}, and also the types: ${typeof interests}`
      );
    }
  };

  return (
    <div className="max-w-sm w-full">
      {/* Step 3: Welcome and Additional Information */}
      <h2 className="text-3xl font-extrabold text-black">Welcome to BUZZY!</h2>
      <p className="text-base text-black font-medium">
        Congratulations, <span>{name}</span>!
      </p>
      <p className="mt-2 text-sm text-gray-600">
        Your email has been verified successfully. Before you continue, please
        set your gender and current location to enhance your BUZZY experience.
      </p>

      {/* ... your form inputs and logic */}
      <form className="mt-4" onSubmit={handleSubmit}>
        {/* Gender Input */}
        <div>
          <label
            htmlFor="gender"
            className="block text-sm font-medium text-black"
          >
            Gender
          </label>
          <select
            id="gender"
            className="mt-1 p-2 select select-primary select-bordered w-full border rounded-md focus:outline-none focus:border-blue-500"
            value={gender}
            onChange={handleGenderChange}
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>

        {/* State Input */}
        <div>
          <label
            htmlFor="state"
            className="block text-sm font-medium text-black"
          >
            State
          </label>
          <select
            id="state"
            value={selectedState}
            onChange={handleStateChange}
            className="mt-1 p-2 select select-primary select-bordered w-full border rounded-md focus:outline-none focus:border-blue-500"
          >
            <option value="">Select State</option>
            {statesAndLGAs.map((stateData) => (
              <option key={stateData.state} value={stateData.state}>
                {stateData.state}
              </option>
            ))}
          </select>
        </div>

        {/* LGA Input */}
        <div>
          <label htmlFor="lga" className="block text-sm font-medium text-black">
            LGA
          </label>
          <select
            id="lga"
            value={selectedLGA}
            onChange={(e) => setSelectedLGA(e.target.value)}
            className="mt-1 select select-primary select-bordered p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
          >
            <option value="">Select LGA</option>
            {lgas.map((lga) => (
              <option key={lga} value={lga}>
                {lga}
              </option>
            ))}
          </select>
        </div>

        {/* Date of Birth Input */}
        <div>
          <label htmlFor="dob" className="block text-sm font-medium text-black">
            Date of Birth
          </label>
          <input
            type="date"
            id="dob"
            value={dob}
            onChange={(e) => setDOB(e.target.value)}
            className="mt-1 input input-primary p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>

        {/* Religion Input */}
        <div>
          <label
            htmlFor="religion"
            className="block text-sm font-medium text-black"
          >
            Religion
          </label>
          <select
            id="religion"
            value={religion}
            onChange={(e) => setReligion(e.target.value)}
            className="mt-1 p-2 select select-primary select-bordered w-full border rounded-md focus:outline-none focus:border-blue-500"
          >
            <option value="">Select Religion</option>
            <option value="Christain">Christian</option>
            <option value="Muslim">Muslim</option>
            <option value="Others">Others</option>
            {/* Add more options based on your requirements */}
          </select>
        </div>

        {/* Interest Input */}
        <div>
          <label
            htmlFor="interest"
            className="block text-sm font-medium text-black"
          >
            Interests (Select up to 3)
          </label>
          <select
            id="interest"
            value={interests}
            onChange={(e) => handleInterestChange(e.target.value)}
            className="mt-1 p-2 w-full select select-primary select-bordered rounded-md focus:outline-none focus:border-blue-500"
          >
            <option value="Fashion">Fashion</option>
            <option value="Comedy">Comedy</option>
            <option value="Short Skits">Short Skits</option>
            <option value="Movies">Movies</option>
            <option value="Shows">Shows</option>
            <option value="Music">Music</option>
            <option value="Politics">Politics</option>
            <option value="Food">Food</option>
            <option value="Cars">Cars</option>
            <option value="Bike">Bike</option>
            <option value="Vendors">Vendors</option>
            <option value="Trade Fair">Trade Fair</option>
            <option value="Personality">Personality</option>
            <option value="Film">Film</option>
          </select>
          {/* Display selected interests as chips/tags */}
          <div className="mt-2 flex flex-wrap">
            {interests.map((interest) => (
              <div
                key={interest}
                className="flex items-center bg-blue-800 text-white px-2 py-1 rounded-full m-1"
                onClick={() => handleInterestChange(interest)}
              >
                <span>{interest}</span>
                <AiOutlineCloseCircle
                  className="ml-1 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleInterestChange(interest);
                  }}
                />
              </div>
            ))}
          </div>
        </div>

        <p className="mt-2 text-sm text-gray-600">
          Prior to choosing your Local Government Area (LGA), please ensure that
          you first select your state.
        </p>

        {/* Continue Button */}
        <div className="mt-6">
          <button
            type="submit"
            className="w-full py-2 px-4 border rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:border-blue-700"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignUpForm3;
