import React, { useEffect, useState } from "react";
import DashNavbar from "../DashNavbar";
import axios from "axios";
import { toast } from "react-toastify";

const ReferralList = () => {
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    const fetchReferrals = async () => {
      try {
        const auth = sessionStorage.getItem("authToken");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/basicdata/user/referrals`,
          {
            headers: {
              Accept: "application/json",
              Authorization: auth,
            },
          }
        );

        if (response.data.Access) {
          setReferrals(response.data.AllRefree);
        }
      } catch (error) {
        console.log("Error fetching referrals:", error);
        toast.error("Failed to fetch referrals");
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    fetchReferrals();
  }, []);

  return (
    <div className="px-3 md:px-10">
      <DashNavbar />
      <div className="grid w-full pt-[3%] md:pt-[1%]">
        <main className="bg-[#FAFAFc] rounded-2xl flex-1 overflow-y-auto p-5">
          <div>
            <div className="text-center text-3xl font-bold text-black">
              Referral List
            </div>
            <div className="overflow-x-auto">
              {loading ? (
                <p className="text-center mt-4">Loading...</p>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Full Name</th>
                      <th>Username</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Email Verified</th>
                      <th>Verified</th>
                      <th>Blocked</th>
                      <th>Referral</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referrals.length === 0 ? (
                      <tr>
                        <td colSpan="9" className="text-center">
                          No referrals
                        </td>
                      </tr>
                    ) : (
                      referrals.map((referral, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{referral.FullName}</td>
                          <td>{referral.Username}</td>
                          <td>{referral.Email}</td>
                          <td>{referral.PhoneNumber}</td>
                          <td>{referral.EmailVerification ? "Yes" : "No"}</td>
                          <td>{referral.Verified ? "Yes" : "No"}</td>
                          <td>{referral.Blocked ? "Yes" : "No"}</td>
                          <td>{referral.Referral || "-"}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ReferralList;
