import React, { useEffect, useState } from "react";
import DashNavbar from "../../../DashNavbar";
import WAEC from "../../../images/waec.png";
import { useMyContext } from "../../../../../context/MyContext";
import { toast } from "react-toastify";
import { MdOutlineCancel } from "react-icons/md";
import axios from "axios";

const ExamWaec = () => {
  const [network, setNetwork] = useState("");
  const [plan, setplan] = useState([])
  const [phoneNumber, setphoneNumber] = useState("");
  const [Quantity, setQuantity] = useState(1)
  const [amount, setAmount] = useState("");
  const [singleAmount, setSingleAmount] = useState(0)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [password, setPassword] = useState("");
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [token] = useState(sessionStorage.getItem("authToken") || "");
  const { setIsLoading } = useMyContext();
  const [specialLoad, setSpecialLoad] = useState(false)
  const [resultModal, setResultModal] = useState(false)
  const [resltDetails, setResltDetails] = useState([{
    pin:"",
  }])

  useEffect(() => {
    async function fetchData() {
      // setIsLoading(true);
      setSpecialLoad(true)
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/wallet/exam/waec/get-plans`,
          {
            method: "GET",
            headers: {
              Authorization: sessionStorage.getItem("authToken"),
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch Data plans");
        }

        const responseData = await response.json();
        // //console.log(responseData.Data.data);
        // setSingleAmount(responseData.Data[0]);
        // setAmount(responseData.Data[0].amount)

        setplan(responseData.Data)
      } catch (error) {
        if (error?.response?.data){}
        console.error(error?.response?.data);
        fetchData()
      }finally{
        // setIsLoading(false);
        setSpecialLoad(false)
      }
    }

    fetchData();
  }, []);

  const handleNetworkChange = (e) => {
    let data= JSON.parse(e.target.value)
    setNetwork(e.target.value);
    setAmount(data.amount)
    setSingleAmount(data.amount)
  };

  const handlephoneNumberChange = (e) => {
    setphoneNumber(e.target.value);
  };

  useEffect(() => {
    // Filter plans based on selected network

    if (network && Quantity>0 && amount>0 &&phoneNumber.length==11) {
      setBtnDisabled(false)
    
    } else {
      setBtnDisabled(true)
    }
  }, [network, Quantity,amount,phoneNumber]);

  const handleQuantityChange = (e) => {
    let Quantity=e.target.value
    if (Quantity<0) return ;
    setQuantity(Quantity)
    setAmount(singleAmount*Quantity);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const SendData = async () => {
    setSpecialLoad(true)
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/wallet/exam/waec/send/plan`,
        {
          AccountNumber: phoneNumber,
          Amount: amount,
          Quantity
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("authToken"),
          },
        }
      );

        setIsPasswordModalOpen(true)

    } catch (error) {
      toast.error(error?.response?.data?.Error?error?.response?.data?.Error:"Error requesting WAEC pin");

    }finally{
      setSpecialLoad(false)
    }
  };

  const openPasswordModal = () => {
    setIsPasswordModalOpen(true);
  };

  const closePasswordModal = () => {
    setIsPasswordModalOpen(false);
    setPassword("");
  };

  const verifyPasswordAndSendData = async () => {
    setSpecialLoad(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/wallet/exam/waec/verify`,
        {
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("authToken"),
          },
        }
      );

      setResltDetails(response.data.Data)
      setResultModal(true)
      toast.success("WAEC pin purchased successfully");
      
    } catch (error) {
      //console.error("Error verifying password:", error);
      toast.error(error?.response?.data?.Error?error.response.data.Error:"Error sending WAEC bills. Please try again.");
    } finally {
      setSpecialLoad(false);
      closePasswordModal(); // Close modal after verification attempt
    }
  };

  const handleCopy = (input) => {
    navigator.clipboard.writeText(input);
    toast.success("Token copied to clipboard");
  };

  return (
    <div className="px-3 md:px-10">
      <DashNavbar />
      <div className="grid w-full pt-[3%] md:pt-[1%]">
        <main className="bg-[rgb(250,250,252)] rounded-2xl flex-1 overflow-y-auto p-5">
          <div>
            <div className="flex items-center justify-center text-2xl flex-col">
              <p className="text-[#213481] font-semibold">
                Pay Examination bills
              </p>
              <p className="text-[#213481] font-bold text-4xl">Buy WAEC Pins</p>
            </div>

            <div className="flex flex-wrap justify-center items-center lg:justify-around mt-12 md:justify-between flex-row">
              <img src={WAEC} className="m-2 lg:w-auto md:w-auto w-11" alt="" />
              
            </div>

            <div className="flex flex-col p-8">

              <select
                className="select select-bordered m-6 my-3"
                value={network}
                onChange={handleNetworkChange}
              >
              
                <option value="">Select Plan</option>
                {plan.map(i=><option value={JSON.stringify(i)}>{i.description}</option>)}
                
              </select>
              
              <input
                type="tel"
                className="input input-bordered m-6 my-3"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={handlephoneNumberChange}
              />

              <input
                type="number"
                className="input input-bordered m-6 my-3"
                placeholder="Quantity"
                value={Quantity}
                disabled
                onChange={handleQuantityChange}
              />

              <input
                type="number"
                className="input input-bordered m-6 my-3"
                placeholder="Amount"
                value={amount}
                disabled
              />

              <button
                disabled={btnDisabled}
                className="btn text-white m-6 my-3 bg-[#213481] hover:bg-[#121f51]"
                onClick={SendData}
              >
                Purchase
              </button>
            </div>
          </div>

          {/* Password Modal */}
          {isPasswordModalOpen && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg flex flex-col p-3">
                <div className="flex flex-row justify-between my-1">
                  <p className="text-sm text-[#213481]">Confirm Transaction</p>
                  <MdOutlineCancel
                    className="text-[#7A0202] cursor-pointer"
                    onClick={closePasswordModal}
                  />
                </div>

                <p className="lg:text-2xl md:text-xl text-lg font-semibold mb-2 text-[#213481]">
                  Continue?
                </p>
                <input
                  type="password"
                  className="input input-bordered border-[#213481] mb-4"
                  placeholder="Enter your password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <button
                  className="text-white btn bg-[#213481]"
                  onClick={verifyPasswordAndSendData}
                >
                  Continue
                </button>
              </div>
            </div>
          )}

          {specialLoad&&(
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg flex flex-col p-3">
                <div className="flex flex-row justify-between my-1">
                  <p className="lg:text-2xl md:text-xl text-lg font-semibold mb-2 text-[#213481]">
                    Please wait...
                  </p>
                 
                </div>

              
              </div>
            </div>
          )}

          {resultModal&&(
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg flex flex-col p-3 w-[90%] md:w-[50%] ">
                <div className="flex flex-row justify-between my-1">
                  <p className="lg:text-2xl md:text-xl text-lg font-semibold mb-2 text-[#213481]">
                  WAEC Pins
                  </p>
                  <MdOutlineCancel
                    className="text-[#7A0202] cursor-pointer"
                    onClick={()=>setResultModal(false)}
                  />
                </div>

                {resltDetails.map((i,index)=>{
                  

                  return(
                    <>
                    <p className="text-sm text-[#213481]">Pin {index}</p>
                      <p className="text-sm text-[#213481]">Pin</p>
                      <input
                        type="text"
                        className="input input-bordered border-[#213481] mb-4 text-lg disabled:text-[#213481] disabled:bg-white"
                        placeholder="Enter your password"
                        value={i.pin}
                        onChange={()=>{}}
                        
                        disabled
                      />

                      <button
                        className="text-white btn bg-[#213481]"
                        onClick={()=>handleCopy(i.pin)}
                      >
                        Copy Pin
                      </button>
                    </>
                  )
                })}
                
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default ExamWaec;
