import React from "react";
import VisitorsHeader from "../component/vistorsheader";
import Bottom from "../component/bottom-section";
import Footer from "../component/footer";
import ChangePassword from "../component/ChangePassword";

const Password = () => {
  return (
    <div>
      <VisitorsHeader />
      <ChangePassword />
      <Bottom />
      <Footer />
    </div>
  );
};

export default Password;
