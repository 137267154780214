import facebook from "../../../images/Facebook.png";
import whatsapp from "../../../images/WhatsApp.png";
import tiktok from "../../../images/TikTok.png";
import instagram from "../../../images/Instagram.png";
import twitter from "../../../images/x.svg";
import twitterx from "../../../images/TwitterX.svg";
import customer from "../../../images/Customer.svg";
import thumbsUp from "../../../images/thumbsup.svg";
import audiomack from "../../../images/Audiomack.svg";
import chatb from "../../../images/ChatB.svg";
import youtube from "../../../images/YouTube.svg";
import gplay from "../../../images/GPlay.svg";
import astore from "../../../images/AppStore.svg";
import linkedin from "../../../images/LinkedIn.svg";
import DashNavbar from "../DashNavbar";
import telegram from "../../../images/Telegram App.svg";
import boomplay from "../../../images/Boomplay.svg";
import threads from "../../../images/mingcute_threads-line.svg";
import { FaEye } from "react-icons/fa";
import eye from "../../../images/eye-icon.svg";

const savedprice = sessionStorage.getItem("productPrices");
const Price = savedprice ? JSON.parse(savedprice) : {};
console.log(Price);
export const platformNames = {
  "0l7f38h4d6325cf1b6r4lf70": {
    id: 1,
    name: "Follow",
    image: customer,
    header: "Get Real People to follow Your page on Social Media",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Followers} per Follow`,
    text1:
      "Get People to follow your social media pages. You can get any number of people to follow your social media page. No social media login details required.",
    platform: [twitterx, instagram, tiktok, facebook, threads],
    platformNames: ["X", "Instagram", "TikTok", "Facebook", "Thread"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  "6cf4bl7f17h8d26302f3f5l7": {
    id: 2,
    name: "Like",
    image: thumbsUp,
    header: "Get People to Like your Social media Post",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Likes} per Likes`,
    text1:
      "Get People to Like your social media posts. You can get any number of people to like your social media posts. No social media login detail is required. Simply enter your post link to get started",
    platform: [twitterx, instagram, tiktok, facebook, threads, youtube],
    platformNames: [
      "X",
      "Instagram",
      "TikTok",
      "Facebook",
      "Thread",
      "Youtube",
    ],
    text2:
      "Your Social Media post Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  "4f6l2h83c0b5717d6f253fl7": {
    id: 3,
    name: "facebook",
    image: facebook,
    header: "Get People to Like and follow your facebook Business page",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Followers} per Follow`,
    text1:
      "Get People to Like and Follow Your Facebook Business Page. You can get any number of people to like and follow your facebook page. No Facebook Login Details is required.",
    platform: [facebook],
    platformNames: ["Facebook"],
    text2: "Your facebook Page/Profile Link",
    text3: "Number of people you want",
  },
  l8hd6340c7bf717f3f5f26l: {
    id: 4,
    name: "audiomack",
    image: audiomack,
    header: "Get real People to follow your Audiomack channel",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Audiomack_follow} per Audiomack Follow`,
    text1:
      "Get People to Follow Your Audiomack Music Channel. You can get any number of people to follow your Audiomack Channel. No Audiomack Login Details is required.",
    platform: [audiomack],
    platformNames: ["Audiomack"],
    text2: "Your Audiomack Channel Link",
    text3: "Number of people you want",
  },
  "67f3l2h8d04c1b75f6f17lf": {
    id: 5,
    name: "comment",
    image: chatb,
    header: "Get People to comment on your Social media Post",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Comment} per Comment`,
    text1:
      "Get Real People to Comment on your social media posts. We DO NOT allow real users or bots to comment on posts.",
    platform: [
      twitterx,
      instagram,
      tiktok,
      facebook,
      threads,
      youtube,
      linkedin,
    ],
    platformNames: [
      "X",
      "Instagram",
      "TikTok",
      "Facebook",
      "Thread",
      "Youtube",
      "LinkedIn",
    ],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  "4cf7b1l72fhd6385032f8f7l": {
    id: 6,
    name: "youtube_subscribe",
    image: youtube,
    header: "Get real People to subscribe on your youtube channel",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Youtube_Subscribe} per Suscription`,
    text1:
      "Get People to Subcribe on your Youtube Channel. The users will subscribe on your channel thereby increasing your subscribers, views, comments and likes. You can get any number of persons to subscribe on your Youtube Channel. Simply enter your Youtube Channel Link to get started.",
    platform: [youtube],
    platformNames: ["Youtube"],
    text2: "Your channel link",
    text3: "Number of people you want",
  },
  "6c4f7l2h8hd6325cf01b7f73": {
    id: 7,
    name: "Views",
    image: eye,
    header: "Get People to view youtube channel videos",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Youtube_likes_view_comment} per View`,
    text1:
      "Get People to view your youtube videos. You can get any number of people to view your social media page. No social media login details required.",
    platform: [youtube],
    platformNames: ["Youtube"],
    text2: "Your Social Media Page/Profile Link (Youtube)",
    text3: "Number of people you want",
  },
  f7l8h6d2c4b1f7b5f32l703: {
    id: 8,
    name: "playStore",
    image: gplay,
    header: "Get People to download and review your app on Google play store",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Playstore_download} per Sownload and review`,
    text1:
      "Get People to View and Comment on your Youtube Channel and Video. The users will watch your video, comment on the video and like the video at the same time thereby increasing your views, comments and likes. You can get any number of people to view and comment on your video. Simply enter the Youtube Video Link to get started",
    platform: [gplay],
    platformNames: ["Play Store"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  f6l2h8d63401cbf5l71f37: {
    id: 9,
    name: "appStore",
    image: astore,
    header: "Get People to download and review your app on Apple  store",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Appstore_download} per Join`,

    text1:
      "Get People to download and review your apps on Apple Store. You can get any number of people to download and review your app. Simply enter your App download link to get started.",
    platform: [astore],
    platformNames: ["Apple store"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  "7f6l2h8d63c4bf71b753f0l": {
    id: 10,
    name: "whatsapp",
    image: whatsapp,
    header: "Get Real People to join your WhatsApp group",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Join} per Join`,
    text1:
      "Get People to follow your social media pages. You can get any number of people to follow your social media page. No social media login details required.",
    platform: [whatsapp],
    platformNames: ["whatsapp"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  f8hd63230f7lc4bf71724l5: {
    id: 11,
    name: "telegram",
    image: telegram,
    header: "Get Real People to join your Telgram group",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Join} per Join`,
    text1:
      "Get People to follow your social media pages. You can get any number of people to follow your social media page. ",
    platform: [telegram],
    platformNames: ["telegram"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  d63529f71724l57lc4bff8h: {
    id: 12,
    name: "linkedin",
    image: linkedin,
    header: "Get Real People to Like and Follow",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.LinkedIn_follow} per Join`,
    text1:
      "Get People to follow your social media pages. You can get any number of people to follow your social media page. No social media login details required.",
    platform: [linkedin],
    platformNames: ["linkedin"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  "9lc4bff8hf71724l57d6352": {
    id: 13,
    name: "boomplay",
    image: boomplay,
    header: "Get Real People to follow your Boomplay  channel",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Boomplay_follow} per Follow`,
    text1:
      "Get Real People to follow your Boomplay Channel today. You can get any number of people to folow your Boomplay channel. No Boomplay login details required.",
    platform: [boomplay],
    platformNames: ["boomplay"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
    method: "Boomplay_follow",
  },

  // Add other platforms as needed
};

export const platformNames2 = {
  "0l7f38h4d6325cf1b6r4lf70": {
    id: 1,
    name: "Follow",
    image: customer,
    header: "Authentic Followers",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Followers} per Follow`,
    text1:
      "Follow selected and given profiles and earn EDO10 per Follower. The more profiles you follow, the more you earn.",
    platform: [
      twitterx,
      instagram,
      tiktok,
      facebook,
      telegram,
      threads,
      linkedin,
    ],
    text2:
      "You have to click on the button below so that your next task will be generated for you. Only one task is generated per time. You have to do the generated task before another one can be generated for you.",
    text3: "Number of people you want",
    method: "Follow",
  },
  "6cf4bl7f17h8d26302f3f5l7": {
    id: 2,
    name: "Like",
    image: thumbsUp,
    header: "Get People to Like your Social media Post",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Likes} per Likes`,
    text1:
      "Get People to Like your social media posts. You can get any number of people to like your social media posts. No social media login detail is required. Simply enter your post link to get started",
    platform: [twitterx, instagram, tiktok, facebook],
    text2:
      "Your Social Media post Link (e.g Instagram, Twitter or Tiktok Page Link)",

    text3: "Number of people you want",
    method: "Like",
  },
  "4f6l2h83c0b5717d6f253fl7": {
    id: 3,
    name: "facebook",
    image: facebook,
    header: "Get People to Like and follow your facebook Business page",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Followers} per Follow`,
    text1:
      "Get People to Like and Follow Your Facebook Business Page. You can get any number of people to like and follow your facebook page. No Facebook Login Details is required.",
    platform: [facebook],
    text2: "Your facebook Page/Profile Link",
    text3: "Number of people you want",
    method: ["Like_Follow_FB_Page"],
  },
  l8hd6340c7bf717f3f5f26l: {
    id: 4,
    name: "audiomack",
    image: audiomack,
    header: "Audiomack Follow",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Audiomack_follow} per Audiomack Follow`,
    text1:
      "Follow Your Audiomack Music Channel. You can get any number of people to follow your Audiomack Channel. No Audiomack Login Details is required.",
    platform: [audiomack],
    text2: "Your Audiomack Channel Link",
    text3: "Number of people you want",
    method: "Audiomack_follow",
  },
  "67f3l2h8d04c1b75f6f17lf": {
    id: 5,
    name: "comment",
    image: chatb,
    header: "Get People to comment on your Social media Post",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Comment} per Comment`,
    text1:
      "Get Real People to Comment on your social media posts. We DO NOT allow real users or bots to comment on posts.",
    platform: [twitterx, instagram, tiktok, facebook, youtube, linkedin],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
    method: "Comment",
  },
  "4cf7b1l72fhd6385032f8f7l": {
    id: 6,
    name: "youtube",
    image: youtube,
    header: "Subscribe to youtube channel",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Youtube_Subscribe} per Suscription`,
    text1:
      "Get People to Subcribe on your Youtube Channel. The users will subscribe on your channel thereby increasing your subscribers, views, comments and likes. You can get any number of persons to subscribe on your Youtube Channel. Simply enter your Youtube Channel Link to get started.",
    platform: [youtube],
    text2: "Your channel link",
    text3: "Number of people you want",
    method: "Youtube_Subscribe",
  },
  "6c4f7l2h8hd6325cf01b7f73": {
    id: 7,
    name: "Views",
    image: eye,
    header: "Watch videos to earn",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Views} per views`,
    text1:
      "Get People to follow your youtube channel. You can get any number of people to follow your social media page. No social media login details required.",
    platform: [twitterx, instagram, tiktok, facebook, youtube, linkedin],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
    method: "View",
  },
  f7l8h6d2c4b1f7b5f32l703: {
    id: 8,
    name: "playStore",
    image: gplay,
    header: "Download and review app on Google play store",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Playstore_download} per download and review`,
    text1:
      "Review apps on playstore. You can get any number of people to download and review your app. Simply enter your App download link to get started.",
    platform: [gplay],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
    method: "Playstore_download",
  },
  f6l2h8d63401cbf5l71f37: {
    id: 9,
    name: "appStore",
    image: astore,
    header: "Download and review app on Apple store",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Appstore_download} per download`,
    text1:
      "Review apps on Apple Store. You can get any number of people to download and review your app. Simply enter your App download link to get started.",
    platform: [astore],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
    method: "Appstore_download",
  },
  "7f6l2h8d63c4bf71b753f0l": {
    id: 10,
    name: "whatsapp",
    image: whatsapp,
    header: "Join WhatsApp group",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Join} per Join`,
    text1:
      "Get People to follow your social media pages. You can get any number of people to follow your social media page. No social media login details required.",
    platform: [whatsapp],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
    method: "Join",
    Platform: "Whatsapp",
  },
  f8hd63230f7lc4bf71724l5: {
    id: 11,
    name: "telegram",
    image: telegram,
    header: "Join Telegram group",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Join} per Join`,
    text1:
      "Get People to follow your social media pages. You can get any number of people to follow your social media page. No social media login details required.",
    platform: [whatsapp],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
    method: "Join",
    Platform: "Telegram",
  },
  "9lc4bff8hf71724l57d6352": {
    id: 12,
    name: "boomplay",
    image: boomplay,
    header: "Follow Boomplay channel",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Boomplay_follow} per Follow`,
    text1:
      "Get Real People to follow your Boomplay Channel today. You can get any number of people to folow your Boomplay channel. No Boomplay login details required.",
    platform: [boomplay],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
    method: "Boomplay_follow",
  },
  d63529f71724l57lc4bff8h: {
    id: 13,
    name: "linkedin",
    image: linkedin,
    header: "Like and follow page on LinkedIn",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.LinkedIn_follow} per Join`,
    text1:
      "Get People to follow your social media pages. You can get any number of people to follow your social media page. No social media login details required.",
    platform: [linkedin],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
    method: "LinkedIn_follow",
  },

  // Add other platforms as needed
};

export const platformNames3 = {
  "0l7f38h4d6325cf1b6r4lf70": {
    id: 1,
    name: "Follow",
    image: customer,
    header: "Get real follows on Your page on Social Media",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Followers} per Follow`,
    text1:
      "Get real follows on your social media pages. You can get any number of real people to follow your social media page. No social media login details required.",
    platform: [twitterx, instagram, tiktok, facebook, threads],
    platformNames: ["Instagram", "x", "TikTok", "Facebook", "youtube"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  "6cf4bl7f17h8d26302f3f5l7": {
    id: 2,
    name: "Like",
    image: thumbsUp,
    header: "Get real Likes on your Social media Post",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Likes} per Likes`,
    text1:
      "Get People to Like your social media posts. You can get any number of people to like your social media posts. No social media login detail is required. Simply enter your post link to get started",
    platform: [twitterx, instagram, tiktok, facebook, threads, youtube],
    platformNames: ["Instagram", "x", "TikTok", "Facebook", "youtube"],
    text2:
      "Your Social Media post Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  "4f6l2h83c0b5717d6f253fl7": {
    id: 3,
    name: "facebook",
    image: facebook,
    header: "Get real Likes and follows on your facebook Business page",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Followers} per Follow`,
    text1:
      "Get real People to Like and Follow Your Facebook Business Page. You can get any number of real people to like and follow your facebook page. No Facebook Login Details is required.",
    platform: [facebook],
    platformNames: ["Facebook"],
    text2: "Your facebook Page/Profile Link",
    text3: "Number of people you want",
  },
  l8hd6340c7bf717f3f5f26l: {
    id: 4,
    name: "audiomack",
    image: audiomack,
    header: "Get real People to follow your Audiomack channel",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Audiomack_follow} per Audiomack Follow`,
    text1:
      "Get People to Follow Your Audiomack Music Channel. You can get any number of people to follow your Audiomack Channel. No Audiomack Login Details is required.",
    platform: [audiomack],
    platformNames: ["Audiomack"],
    text2: "Your Audiomack Channel Link",
    text3: "Number of people you want",
  },
  "67f3l2h8d04c1b75f6f17lf": {
    id: 5,
    name: "comment",
    image: chatb,
    header: "Get People to comment on your Social media Post",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Comment} per Comment`,
    text1:
      "Get Real People to Comment on your social media posts. We DO NOT allow real users or bots to comment on posts.",
    platform: [twitterx, instagram, tiktok, facebook, youtube, linkedin],
    platformNames: [
      "X",
      "Instagram",
      "TikTok",
      "Facebook",
      "Thread",
      "Youtube",
      "LinkedIn",
    ],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  "4cf7b1l72fhd6385032f8f7l": {
    id: 6,
    name: "youtube_subscribe",
    image: youtube,
    header: "Get real People to subscribe on your youtube channel",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Youtube_Subscribe} per Suscription`,
    text1:
      "Get People to Subcribe on your Youtube Channel. The users will subscribe on your channel thereby increasing your subscribers, views, comments and likes. You can get any number of persons to subscribe on your Youtube Channel. Simply enter your Youtube Channel Link to get started.",
    platform: [youtube],
    platformNames: ["Youtube"],
    text2: "Your channel link",
    text3: "Number of people you want",
  },
  "6c4f7l2h8hd6325cf01b7f73": {
    id: 7,
    name: "Views",
    image: eye,
    header: "Get People to view youtube channel videos",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Youtube_likes_view_comment} per View`,
    text1:
      "Get People to view your youtube videos. You can get any number of people to view your social media page. No social media login details required.",
    platform: [youtube],
    platformNames: ["Youtube"],
    text2: "Your Social Media Page/Profile Link (Youtube)",
    text3: "Number of people you want",
  },
  f7l8h6d2c4b1f7b5f32l703: {
    id: 8,
    name: "playStore",
    image: gplay,
    header: "Get People to download and review your app on Google play store",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Playstore_download} per Sownload and review`,
    text1:
      "Get People to View and Comment on your Youtube Channel and Video. The users will watch your video, comment on the video and like the video at the same time thereby increasing your views, comments and likes. You can get any number of people to view and comment on your video. Simply enter the Youtube Video Link to get started",
    platform: [gplay],
    platformNames: ["Play Store"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  f6l2h8d63401cbf5l71f37: {
    id: 9,
    name: "appStore",
    image: astore,
    header: "Get People to download and review your app on Apple  store",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Appstore_download} per Join`,

    text1:
      "Get People to download and review your apps on Apple Store. You can get any number of people to download and review your app. Simply enter your App download link to get started.",
    platform: [astore],
    platformNames: ["Apple store"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  "7f6l2h8d63c4bf71b753f0l": {
    id: 10,
    name: "whatsapp",
    image: whatsapp,
    header: "Get Real People to join your WhatsApp group",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Join} per Join`,
    text1:
      "Get People to follow your social media pages. You can get any number of people to follow your social media page. No social media login details required.",
    platform: [whatsapp],
    platformNames: ["whatsapp"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  f8hd63230f7lc4bf71724l5: {
    id: 11,
    name: "telegram",
    image: telegram,
    header: "Get Real People to join your Telgram group",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Join} per Join`,
    text1:
      "Get People to follow your social media pages. You can get any number of people to follow your social media page. ",
    platform: [telegram],
    platformNames: ["telegram"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  d63529f71724l57lc4bff8h: {
    id: 12,
    name: "linkedin",
    image: linkedin,
    header: "Get Real People to Like and Follow",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.LinkedIn_follow} per Join`,
    text1:
      "Get People to follow your social media pages. You can get any number of people to follow your social media page. No social media login details required.",
    platform: [linkedin],
    platformNames: ["LinkedIn"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  "9lc4bff8hf71724l57d6352": {
    id: 13,
    name: "boomplay",
    image: boomplay,
    header: "Get Real People to follow your Boomplay  channel",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Boomplay_follow} per Follow`,
    text1:
      "Get Real People to follow your Boomplay Channel today. You can get any number of people to folow your Boomplay channel. No Boomplay login details required.",
    platform: [boomplay],
    platformNames: ["boomplay"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
    method: "Boomplay_follow",
  },

  // Add other platforms as needed
};

export const platformNames3Fake = {

  //used
  "0l7f38h4d6325cf1b6r4lf70": {
    id: 1,
    name: "Follow",
    image: customer,
    link:"follow",
    header: "Get Fake follows on Your page on Social Media",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Followers} per Follow`,
    text1:
      "Get Fake follows on your social media pages. You can get any number of Fake people to follow your social media page. No social media login details required.",
    platform: [twitterx, instagram, tiktok, facebook, threads],
    platformNames: ["ig", "x", "tiktok", "fb", "thread"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  //used
  "6cf4bl7f17h8d26302f3f5l7": {
    id: 2,
    name: "Like",
    image: thumbsUp,
    link:"likes",
    header: "Get Fake Likes on your Social media Post",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Likes} per Likes`,
    text1:
      "Get People to Like your social media posts. You can get any number of people to like your social media posts. No social media login detail is required. Simply enter your post link to get started",
    platform: [twitterx, instagram, tiktok, youtube],
    platformNames: ["ig", "x", "tiktok", "youtube"],
    text2:
      "Your Social Media post Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },

  //used
  "4f6l2h83c0b5717d6f253fl7": {
    id: 3,
    name: "facebook",
    image: facebook,
    link:"fblikesfollow",
    header: "Get Fake Likes and follows on your facebook Business page",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Followers} per Follow`,
    text1:
      "Get Fake People to Like and Follow Your Facebook Business Page. You can get any number of Fake people to like and follow your facebook page. No Facebook Login Details is required.",
    platform: [facebook],
    platformNames: ["Facebook"],
    text2: "Your facebook Page/Profile Link",
    text3: "Number of people you want",
  },

  //used
  l8hd6340c7bf717f3f5f26l: {
    id: 4,
    name: "audiomack",
    image: audiomack,
    link:"audiomackfollow",
    header: "Get Fake People to follow your Audiomack channel",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Audiomack_follow} per Audiomack Follow`,
    text1:
      "Get People to Follow Your Audiomack Music Channel. You can get any number of people to follow your Audiomack Channel. No Audiomack Login Details is required.",
    platform: [audiomack],
    platformNames: ["Audiomack"],
    text2: "Your Audiomack Channel Link",
    text3: "Number of people you want",
  },
  "4cf7b1l72fhd6385032f8f7l": {
    id: 6,
    name: "youtube_subscribe",
    image: youtube,
    header: "Get Fake People to subscribe on your youtube channel",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Youtube_Subscribe} per Suscription`,
    text1:
      "Get People to Subcribe on your Youtube Channel. The users will subscribe on your channel thereby increasing your subscribers, views, comments and likes. You can get any number of persons to subscribe on your Youtube Channel. Simply enter your Youtube Channel Link to get started.",
    platform: [youtube],
    platformNames: ["Youtube"],
    text2: "Your channel link",
    text3: "Number of people you want",
  },

  //used
  "67f3l2h8d04c1b75f6f17lf": {
    id: 7,
    name: "TikTok",
    image: tiktok,
    link:"tiktokview",
    header: "Get People to view Tiktok  videos",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Youtube_likes_view_comment} per View`,
    text1:
      "Get People to view your Tiktok videos. You can get any number of people to view your social media page. No social media login details required.",
    platform: [tiktok],
    platformNames: ["tiktok"],
    text2: "Your Social Video Link (tiktok)",
    text3: "Number of people you want",
  },
  f7l8h6d2c4b1f7b5f32l703: {
    id: 8,
    name: "playStore",
    image: gplay,
    header: "Get People to download and review your app on Google play store",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Playstore_download} per Sownload and review`,
    text1:
      "Get People to View and Comment on your Youtube Channel and Video. The users will watch your video, comment on the video and like the video at the same time thereby increasing your views, comments and likes. You can get any number of people to view and comment on your video. Simply enter the Youtube Video Link to get started",
    platform: [gplay],
    platformNames: ["Play Store"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  f6l2h8d63401cbf5l71f37: {
    id: 9,
    name: "appStore",
    image: astore,
    header: "Get People to download and review your app on Apple  store",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Appstore_download} per Join`,

    text1:
      "Get People to download and review your apps on Apple Store. You can get any number of people to download and review your app. Simply enter your App download link to get started.",
    platform: [astore],
    platformNames: ["Apple store"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },

  //used
  "7f6l2h8d63c4bf71b753f0l": {
    id: 10,
    name: "whatsapp",
    image: whatsapp,
    link:"joinwhatsapchannel",
    header: "Get Fake People to join your WhatsApp group",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Join} per Join`,
    text1:
      "Get People to follow your social media pages. You can get any number of people to follow your social media page. No social media login details required.",
    platform: [whatsapp],
    platformNames: ["whatsapp"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  f8hd63230f7lc4bf71724l5: {
    id: 11,
    name: "telegram",
    image: telegram,
    header: "Get Fake People to join your Telgram group",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Join} per Join`,
    text1:
      "Get People to follow your social media pages. You can get any number of people to follow your social media page. ",
    platform: [telegram],
    platformNames: ["telegram"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  d63529f71724l57lc4bff8h: {
    id: 12,
    name: "linkedin",
    image: linkedin,
    header: "Get Fake People to Like and Follow",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.LinkedIn_follow} per Join`,
    text1:
      "Get People to follow your social media pages. You can get any number of people to follow your social media page. No social media login details required.",
    platform: [linkedin],
    platformNames: ["linkedin"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
  },
  "9lc4bff8hf71724l57d6352": {
    id: 13,
    name: "boomplay",
    image: boomplay,
    header: "Get Fake People to follow your Boomplay  channel",
    pricing: `Pricing: EDO${Price?.EarnData?.Engagment.Boomplay_follow} per Follow`,
    text1:
      "Get Fake People to follow your Boomplay Channel today. You can get any number of people to folow your Boomplay channel. No Boomplay login details required.",
    platform: [boomplay],
    platformNames: ["boomplay"],
    text2:
      "Your Social Media Page/Profile Link (e.g Instagram, Twitter or Tiktok Page Link)",
    text3: "Number of people you want",
    method: "Boomplay_follow",
  },

  // Add other platforms as needed
};
