import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/buzzy-logo.png";


const Footer = () => {
  return (
    <footer className="pt-16 pb-5 px-6">
      <div className="md:w-[92%] mx-auto grid md:grid-cols-2 gap-10">
        <div className="footer-left-side mr-10">
          <div className="logo flex">
            <div className="logo-image">
              <img src={logo} alt="Logo" className="h-20 mr-3" />
            </div>
            <h1 className="text-4xl text-[#405AC6] flex justify-center uppercase font-bold py-5">
              Buzzy.ng
            </h1>
          </div>
          <h2 className="text-base font-medium text-[#405AC6] my-2 max-w-3xl">
            Join our newsletter to stay up to date on feature and release.
          </h2>
          <form className="flex my-2 p-1 border-[3px] border-[#405AC6] rounded">
            <input
              type="email"
              placeholder="Enter your email"
              className="w-full text-[#405AC6] px-3 py-2 rounded-l mr-2 focus:outline-none"
            />
            <button
              type="submit"
              className="sub bg-[#405AC6] hover:bg-blue-900 text-white px-4 py-2 rounded focus:outline-none"
            >
              Subscribe
            </button>
          </form>
          <h2 className="text-base flex max-w-3xl text-[#405AC6]">
            By subscribing you agree to our Privacy Policy and provice consent
            to receive updates from our Company.
          </h2>
          <div className="my-2 text-[#405AC6] font-normal text-2xl ">
            <a href="https://wa.me/2349049392160">0904 939 2160</a>
          </div>
          <div className="mb-2 text-[#405AC6] font-normal text-2xl ">
            <a href="mailto:Support@buzzy.ng">Support@buzzy.ng</a>
          </div>
        </div>
        <div className="w-full grid md:grid-cols-3 gap-4">
          <div className="flex flex-col">
            <h2 className="text-[17px] text-[#405AC6] font-semibold mb-4">
              Company
            </h2>
            <ul className="list-none">
              <li className="mb-2 text-[#405AC6] font-normal text-base">
                <a href="/about">About</a>
              </li>
              <li className="mb-2 text-[#405AC6] font-normal text-base">
                <a href="/terms">Terms</a>
              </li>
              <li className="mb-2 text-[#405AC6] font-normal text-base">
                <a href="/refundpolicy">Refund Policy</a>
              </li>
              <li className="mb-2 text-[#405AC6] font-normal text-base">
                <a href="/privacypolicy">Privacy Policy</a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col">
            <h2 className="text-[17px] text-[#405AC6] font-semibold mb-4">
              Quick Link
            </h2>
            <ul className="list-none">
              <li className="mb-2 text-[#405AC6] font-normal text-base">
                <a href="/login">Get Started</a>
              </li>
              <li className="mb-2 text-[#405AC6] font-normal text-base">
                <a href="/login">Login</a>
              </li>
              <li className="mb-2 text-[#405AC6] font-normal text-base">
                <a href="/earn">Earn</a>
              </li>
              <li className="mb-2 text-[#405AC6] font-normal text-base">
                <a href="/pricing">Pricing</a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col">
            <h2 className="text-[17px] text-[#405AC6] font-semibold mb-4">
              Support
            </h2>
            <ul className="list-none">
              <li className="mb-2 text-[#405AC6] font-normal text-base">
                <a href="#">Contact Support</a>
              </li>
              <li className="mb-2 text-[#405AC6] font-normal text-base">
                <a href="#">Frequently Asked Questions (FAQ)</a>
              </li>
            </ul>
            <h2 className="text-[17px] text-[#405AC6] font-semibold mb-4">
              Social Media
            </h2>
            <ul className="list-none">
              <li className="mb-2 text-[#405AC6] font-normal text-base">
                <a href="https://www.facebook.com/buzzymarketplace?mibextid=ZbWKwL">
                  Follow on Facebook
                </a>
              </li>
              <li className="mb-2 text-[#405AC6] font-normal text-base">
                <a href="https://www.instagram.com/buzzy.ng?igsh=eG5ja2FqeHd1cDF3">
                  Follow on Instagram
                </a>
              </li>
              <li className="mb-2 text-[#405AC6] font-normal text-base">
                <a href="https://x.com/Buzzy_ng?t=tiBai3mED7TiCHk5rjRUXA&s=09">
                  Follow on X
                </a>
              </li>
              <li className="mb-2 text-[#405AC6] font-normal text-base">
                <a href="https://www.linkedin.com/company/buzzy-ng/">
                  Follow on Linkedin
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bg-[#405AC6] md:w-[92%] mx-auto h-[2px] my-6"></div>
      <div className="text-start mt-4 md:w-[92%] mx-auto">
        <p className="text-base text-[#405AC6] font-thin">
          &copy; 2024 Buzzy. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
