import React, { useState } from "react";
import DashNavbar from "../DashNavbar";
import { FaArrowLeft } from "react-icons/fa";
import { CiCirclePlus } from "react-icons/ci";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useMyContext } from "../../../context/MyContext";

const UploadForm = () => {
  const [title, setTitle] = useState("");
  const [stockQuantities, setStockQuantities] = useState(1);
  const [price, setPrice] = useState("");
  const [marketersPrice, setMarketersPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { product } = useParams();
  const { isLoading, setIsLoading } = useMyContext();

  const handleImageUpload = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setImages([...images, ...selectedFiles]);
    const fileNamesArr = selectedFiles.map((file) => file.name);
    setFileNames([...fileNames, ...fileNamesArr]);
  };

  const handleConfirmAdvert = async () => {
    let errorMessage = "";
    if (!title) {
      errorMessage += "Please provide a title.\n";
    }
    if (!stockQuantities) {
      errorMessage += "Please enter the stock quantity.\n";
    }
    if (!price) {
      errorMessage += "Please enter the price.\n";
    }
    if (!marketersPrice) {
      errorMessage += "Please enter the marketer's price.\n";
    }
    if (!description) {
      errorMessage += "Please enter a description.\n";
    }
    if (images.length === 0) {
      errorMessage += "Please upload at least one image.\n";
    }

    if (errorMessage !== "") {
      toast.error(errorMessage);
      return;
    }

    setIsLoading(true);
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/seller/advertise/2/${product}`;

      const formData = new FormData();
      formData.append("Title", title);
      formData.append("StockQuantities", stockQuantities);
      formData.append("Price", price);
      formData.append("MarketersPrice", marketersPrice);
      formData.append("Description", description);

      images.forEach((image) => {
        formData.append("Images", image);
      });

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: sessionStorage.getItem("authToken"),
        },
        body: formData,
      });

      const responseData = await response.json();

      if (responseData.Error) {
        toast.error(responseData.Error);
        setShowModal(false);
      } else {
        navigate("/"); // Replace with the desired success page
        toast.success("Advertisement Created Successfully");
      }
    } catch (error) {
      setShowModal(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageDeselect = (indexToRemove) => {
    const updatedImages = images.filter((_, index) => index !== indexToRemove);
    const updatedFileNames = fileNames.filter(
      (_, index) => index !== indexToRemove
    );
    setImages(updatedImages);
    setFileNames(updatedFileNames);
  };

  return (
    <div className="px-3 md:px-10">
      <DashNavbar />
      <div className="grid w-full pt-[3%] md:pt-[1%]">
        <main className="bg-[#FAFAFc] rounded-2xl flex-1 overflow-y-auto p-5 text-black">
          <div>
            {/*  */}
            <div className="flex flex-row items-center justify-">
              <FaArrowLeft className="mr-4" />
              <p className="lg:text-3xl md:text-3xl text-xl font-semibold">
                Upload your products
              </p>
            </div>
            {/*  */}
            <div className="bg-[#405AC6] p-8 bg-opacity-20 rounded-md border-3 border-[#405AC6] flex mt-8">
              <h1 className="lg:text-2xl md:text-sm text-xl- font-bold text-[#405AC6]">
                Step 2 : Upload product Details
              </h1>
            </div>
            {/*  */}
            <div className="mt-8 flex flex-row flex-wrap">
              {/* Image upload div */}
              {images.map((image, index) => (
                <div
                  key={index}
                  className="justify-center items-center bg-[#F1F1F5] p-4 rounded-xl flex flex-col relative m-2"
                >
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`Uploaded ${index}`}
                    className="w-52 h-52 object-cover rounded-xl"
                  />
                  <p className="max-w-xs mt-2  text-xs glass p-2 rounded-xl shadow-md">
                    {fileNames[index]}
                  </p>
                  <button
                    className="absolute top-0 right-0 mt-1 mr-1 p-1 rounded-full bg-red-500 text-white text-xs"
                    onClick={() => handleImageDeselect(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <label htmlFor="fileInput" className="cursor-pointer">
                <div className="justify-center items-center p-8 bg-[#F1F1F5] pt-12 rounded-xl flex flex-col">
                  <CiCirclePlus className="text-[#213481] text-4xl" />
                  <p className="-">Upload Photo</p>
                </div>
              </label>
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                multiple
                className="hidden"
                onChange={handleImageUpload}
              />
            </div>

            {/* Title Input */}
            <div className="flex my-4 mt-8">
              <h2 className="lg:text-2xl md:text-2xl text-xl  text-black font-semibold mr-2">
                Title
              </h2>
            </div>
            <div>
              <input
                type="text"
                className="w-full border-b-2 border-[#AEAEAE] focus:outline-none text-black lg:text-2xl md:text-2xl text-xl"
                placeholder="Enter a clear and attractive product title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <p className="text-gray-400 text-xs mt-6">
                The title of your product should be very accurate and
                attractive. E.g the title of a fashion product can be 'High
                Quality Round Neck Men's Shirt - Blue'
              </p>
            </div>
            {/* Stock Quantities Input */}
            <div className="flex my-4 mt-8">
              <h2 className="lg:text-2xl md:text-2xl text-xl text-black font-semibold mr-2">
                Stock Quantities
              </h2>
            </div>
            <div>
              <input
                type="number"
                className="w-full border-b-2 border-[#AEAEAE] focus:outline-none text-black lg:text-2xl md:text-2xl text-xl"
                placeholder="Enter amount of quantities"
                value={stockQuantities}
                onChange={(e) => setStockQuantities(e.target.value)}
              />
              <p className="text-gray-400 text-xs mt-6">
                Please enter valid number of product quantities you have left in
                your stock. You can update this value frequently so buyers will
                know what you have in stock.
              </p>
            </div>
            {/* Price Input */}
            <div className="flex my-4 mt-8">
              <h2 className="lg:text-2xl md:text-2xl text-xl  text-black font-semibold mr-2">
                Price
              </h2>
            </div>
            <div className="flex items-center">
              <span className="text-gray-500 text-2xl">₦</span>
              <input
                type="number"
                className="w-full border-b-2 border-[#AEAEAE] focus:outline-none text-black lg:text-2xl md:text-2xl text-xl pl-2"
                placeholder="Enter product price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
            <p className="text-gray-400 text-xs mt-6">
              Please make sure your price is very affordable and competitive.
              Good Products with Great prices get massive sales within a short
              period of time.
            </p>

            {/* Marketer’s Price Input */}
            <div className="flex my-4 mt-8">
              <h2 className="lg:text-2xl md:text-2xl text-xl  text-black font-semibold mr-2">
                Marketer’s Price
              </h2>
            </div>
            <div className="flex items-center">
              <span className="text-gray-500 text-2xl">₦</span>
              <input
                type="number"
                className="w-full border-b-2 border-[#AEAEAE] focus:outline-none text-black lg:text-2xl md:text-2xl text-xl pl-2"
                placeholder="Enter marketer’s price"
                value={marketersPrice}
                onChange={(e) => setMarketersPrice(e.target.value)}
              />
            </div>
            {/* Description Textarea */}
            <div className="flex my-4 mt-8">
              <h2 className="lg:text-2xl md:text-2xl text-xl  text-black font-semibold mr-2">
                Description
              </h2>
            </div>
            <div>
              <textarea
                className="w-full border-none focus:outline-none text-black"
                placeholder="Enter your advert text or caption..."
                rows="4"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            {/*  */}
            <button
              className="btn btn-primary lg:ml-8 md:ml-8 text-2xl lg:mt-0 sm:mt-8 md:mt-0 mt-8"
              onClick={() => setShowModal(true)}
            >
              Upload Products
            </button>

            {/* Confirm Advert Modal */}
            {showModal && (
              <div className="fixed inset-0 z-50 flex items-center bg-black bg-opacity-50 justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                <div className="bg-white p-8 max-w-md rounded-md">
                  <h2 className="text-xl font-bold mb-4 text-center">
                    Confirm advert placement
                  </h2>
                  <p className="text-gray-600 mb-4">
                    Are you sure you want to place this advert? This action
                    cannot be undone once it is completed.
                  </p>
                  <div className="flex justify-center">
                    <button
                      className="btn btn-primary mx-1 rounded-full"
                      onClick={handleConfirmAdvert}
                    >
                      Yes
                    </button>
                    <button
                      className="btn btn-error mx-1 rounded-full"
                      onClick={() => setShowModal(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default UploadForm;
