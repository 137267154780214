import React, { useState, useEffect } from "react";
import DashNavbar from "../../../DashNavbar";
import JAMB from "../../../images/Jamb.png";


// import Modal from "./Modal"
import axios from "axios";
import { toast } from "react-toastify";
import { useMyContext } from "../../../../../context/MyContext";
import { MdOutlineCancel } from "react-icons/md";

const ExamJamb = () => {
  const [JAMBPlan, setJAMBPlan] = useState("");
  const [ComfirmationCode, setComfirmationCode] = useState("")
  const [PricePlan, setPricePlan] = useState(0)
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [plans, setPlans] = useState([]);
  const [customer, setcustomer] = useState({name:"",number:""})
  const { setIsLoading } = useMyContext();
  const [specialLoad, setSpecialLoad] = useState(false)
  const [resultModal, setResultModal] = useState(false)
  const [resltDetails, setResltDetails] = useState({
    "referenceID": "",
    "pin": "",
  })

  const [ButtonSubmit, setButtonSubmit] = useState(true)

  useEffect(() => {
    async function fetchData() {
      setSpecialLoad(true)
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/wallet/exam/jamb/get-plans`,
          {
            method: "GET",
            headers: {
              Authorization: sessionStorage.getItem("authToken"),
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch JAMB plans");
        }

        const responseData = await response.json();
        // //console.log(responseData.Data.data);
        setPlans(responseData.Data);
      } catch (error) {
        //console.error(error.message);
        fetchData()
      }finally{
        setSpecialLoad(false)

      }
    }

    fetchData();
  }, []);
  
  useEffect(() => {
    if (ComfirmationCode.length>9&&ComfirmationCode.length<13&&JAMBPlan) {
      handleResolveCustomer()
    }else{
      setButtonSubmit(true)
    }
  }, [JAMBPlan,ComfirmationCode]);

  const handleJAMBPlanChange = (e) => {
    let thepack=(plans.find(i=>i.service_type==e.target.value))
    setJAMBPlan(e.target.value)
    setPricePlan(thepack?.amount)
    setComfirmationCode("")
    setcustomer({name:"",number:""})
  };


  const openModal = () => {
    setIsPasswordModalOpen(true);
  };

  const closeModal = () => {
    setIsPasswordModalOpen(false);
    setPassword(""); // Clear password field on modal close
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleCopy = (input) => {
    navigator.clipboard.writeText(input);
    toast.success("Token copied to clipboard");
  };

  const sendData = async () => {
    // setIsLoading(true)
    setSpecialLoad(true)
    setButtonSubmit(true)
    try {

      await axios.post(
        `${process.env.REACT_APP_API_URL}/wallet/exam/jamb/send/plan`,
        {
          ServiceType: JAMBPlan,
          AccountNumber: customer.number,
          Amount: PricePlan,
          Confirmation_code:ComfirmationCode,
          
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("authToken"),
          },
        }
      );
      setIsPasswordModalOpen(true)

    } catch (error) {
      //console.error("Error sending Data:", error.message);
      toast.error(error?.response?.data?.Error?error.response.data.Error:"Error sending JAMB bills. Please try again.");
      
    }finally{
      setButtonSubmit(false)
      setSpecialLoad(false)

      // setIsLoading(false)
    }
  };

  const handleResolveCustomer = async () => {
    // setIsLoading(true)
    setSpecialLoad(true)
    try {
      setcustomer({
        name:"loading...",
        number:"loading..."
      })
      

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/wallet/exam/jamb/resolvecustomer?confirmationCode=${ComfirmationCode}&ServiceType=${JAMBPlan}`,
        
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("authToken"),
          },
        }
      );

      // console.log(response.data);

      setcustomer({
        name:`${response.data.Data.first_name} ${response.data.Data.last_name} `,
        number:response.data.Data.phone_number
      })
      setButtonSubmit(false)
    } catch (error) {
      // console.log(error);
      setcustomer({
        name:"Invalid Comfirmation Code",
        number:"Invalid Comfirmation Code"
      })
      //console.error("Error sending Data:", error.message);
      // toast.error("Error sending Data. Please try again.");
    }finally{
      // setIsLoading(false)
      setSpecialLoad(false)

    }
  };

  const handlePurchaseWithPassword = async () => {
    setSpecialLoad(true)
    try {
      const verifyResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/wallet/exam/jamb/verify`,
        {
          password: password,
        },
        {
          headers: {
            Authorization: `${sessionStorage.getItem("authToken")}`,
          },
        }
      );

      setResltDetails(verifyResponse.data.Data)
        setResultModal(true)
      toast.success("Jamb bill sent successfully");  
      
    } catch (error) {
      //console.error("Error purchasing data:", error.request.data);
      //console.log(error);
      toast.error(error?.response?.data?.Error?error.response.data.Error:"Error sending JAMB bills. Please try again.");

      // Handle error
    }finally{
      setSpecialLoad(false)
      setIsPasswordModalOpen(false)
    }
  };

  return (
    <div className="px-3 md:px-10">
      <DashNavbar />
      {isPasswordModalOpen&&(
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg flex flex-col p-3">
                <div className="flex flex-row justify-between my-1">
                  <p className="text-sm text-[#213481]">Confirm Transaction</p>
                  <MdOutlineCancel
                    className="text-[#7A0202] cursor-pointer"
                    onClick={closeModal}
                  />
                </div>

                <p className="lg:text-2xl md:text-xl text-lg font-semibold mb-2 text-[#213481]">
                  Continue?
                </p>
                <input
                  type="password"
                  className="input input-bordered border-[#213481] mb-4"
                  placeholder="Enter your password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <button
                  className="text-white btn bg-[#213481]"
                  onClick={handlePurchaseWithPassword}
                >
                  Continue
                </button>
              </div>
            </div>
          )}

      {specialLoad&&(
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg flex flex-col p-3">
                <div className="flex flex-row justify-between my-1">
                  <p className="lg:text-2xl md:text-xl text-lg font-semibold mb-2 text-[#213481]">
                    Please wait...
                  </p>
                 
                </div>

              
              </div>
            </div>
          )}

      {resultModal&&(
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg flex flex-col p-3 w-[90%] md:w-[50%] ">
                <div className="flex flex-row justify-between my-1">
                  <p className="lg:text-2xl md:text-xl text-lg font-semibold mb-2 text-[#213481]">
                  Jamb Pin
                  </p>
                  <MdOutlineCancel
                    className="text-[#7A0202] cursor-pointer"
                    onClick={()=>setResultModal(false)}
                  />
                </div>

                    
                    <>

                      <p className="text-sm text-[#213481]">Reference ID</p>
                      <input
                        type="text"
                        className="input input-bordered border-[#213481] mb-4"
                        value={resltDetails.referenceID}
                        disabled
                      />

                      <p className="text-sm text-[#213481]">Pin</p>
                      <input
                        type="text"
                        className="input input-bordered border-[#213481] mb-4 text-lg disabled:text-[#213481] disabled:bg-white"
                        placeholder="Enter your password"
                        value={resltDetails.pin}
                        
                        disabled
                      />

                      <button
                        className="text-white btn bg-[#213481]"
                        onClick={()=>handleCopy(resltDetails.pin)}
                      >
                        Copy Pin
                      </button>
                    </>
                 
                
              </div>
            </div>
          )}
      <div className="grid w-full pt-[3%] md:pt-[1%]">
        <main className="bg-[rgb(250,250,252)] rounded-2xl flex-1 overflow-y-auto p-5">
          <div>
            <div className="flex items-center justify-center text-2xl flex-col">
              <p className="text-[#213481] font-semibold">
                Pay For JAMB
              </p>
              <p className="text-[#213481] font-bold text-4xl">Buy JAMB Bills</p>
            </div>

            <div className="flex flex-wrap justify-center items-center lg:justify-around mt-12 md:justify-between flex-row">
              <img src={JAMB} className="m-2 lg:w-auto md:w-auto w-11" alt="" />
              
            </div>

            <div className="flex flex-col p-8">
            

              <>
               
               {/* plans  */}
                <select
                  className="select select-bordered m-6 my-3"
                  value={JAMBPlan}
                  onChange={handleJAMBPlanChange}
                >
                  <option value="">Jamb Service</option>
                  {plans?.map((plan) => (
                    <option key={plan.id} value={plan.service_type}>
                      {plan.name} {plan.service_type=='DE'?"Direct Entry":plan.service_type}
                    </option>
                  ))}
                </select>
                
                {/* comfirmation code */}
                <input
                  type="number"
                  className="input input-bordered m-6 my-3"
                  placeholder="Comfirmation code"
                  value={ComfirmationCode}
                  onChange={(e)=>setComfirmationCode(e.target.value)}
                />

                {/* price  */}
                <input
                  type="number"
                  className="input input-bordered m-6 my-3"
                  placeholder="Jamb package price"
                  value={PricePlan}
                  disabled
                />

                <input
                  type="number"
                  className="input input-bordered m-6 my-3"
                  placeholder="Phone Number"
                  value={customer.number}
                  disabled
                />

                {/* price  */}
                <input
                  type="text"
                  className="input input-bordered m-6 my-3"
                  placeholder="Customer details"
                  value={customer.name}
                  disabled
                />
                

                <button
                  disabled={ButtonSubmit}
                  className="btn text-white m-6 my-3 bg-[#213481] hover:bg-[#121f51] disabled:bg-slate-400"
                  onClick={sendData}
                >
                  Purchase
                </button>
              </>
            </div>
          </div>

          
        </main>
      </div>

      
                
      
    </div>
  );
};

export default ExamJamb;
