// MyContextProvider.js
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import io from "socket.io-client";

const MyContext = createContext();

const MyContextProvider = ({ children }) => {
  const [someValue, setSomeValue] = useState(`Default Value`);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(``);
  const [authenticationData, setAuthenticationData] = useState(null);
  const [clientLogged, setClientLogged] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userDataE, setUserDataE] = useState(null); // New state for just user data
  const [advertTasks, setAdvertTasks] = useState([]);
  const [chatRooms, setChatRooms] = useState([]);
  const [socket, setSocket] = useState(null);
  const [productPrices, setProductPrices] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const setLoading = (loading) => {
    setIsLoading(loading);
  };

  const setEmailState = (newEmail) => {
    setEmail(newEmail);
  };

  const saveAuthenticationData = (data) => {
    sessionStorage.setItem(`authToken`, data.Auth);
    setAuthenticationData(data);
  };

  const fetchData = async () => {
    try {
      const authToken = sessionStorage.getItem(`authToken`);

      const response = await axios.get(
        // `${process.env.REACT_APP_API_URL}basicdata/user/user`,
        `${process.env.REACT_APP_API_URL}/basicdata/user/user`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );

      // Save user data to both session and userDataE
      sessionStorage.setItem(`userData`, JSON.stringify(response.data.Data));
      setUserDataE(response.data.Data);

      sessionStorage.setItem(`userDataE`, JSON.stringify(response.data.Data));
      setUserDataE(response.data.Data);

      setUserData(response.data.Data);
    } catch (error) {}
  };

  const fetchUserFunds = async () => {
    try {
      const authToken = sessionStorage.getItem(`authToken`);

      const response = await axios.get(
        // `${process.env.REACT_APP_API_URL}basicdata/user/funds`,
        `${process.env.REACT_APP_API_URL}/basicdata/user/funds`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );

      const fundsData = response.data.Data;

      // Save user funds to both session and userDataE
      sessionStorage.setItem(
        `userData`,
        JSON.stringify({
          ...userData,
          Funds: fundsData.BalanceDetails.Balance,
          Marketer: fundsData.BalanceDetails.Marketer,
          Transactions: fundsData.Transaction,
        })
      );
      setUserDataE((prevUserData) => ({
        ...prevUserData,
        Funds: fundsData.BalanceDetails.Balance,
        Marketer: fundsData.BalanceDetails.Marketer,
        Transactions: fundsData.Transaction,
      }));

      setUserData((prevUserData) => ({
        ...prevUserData,
        Funds: fundsData.BalanceDetails.Balance,
        Marketer: fundsData.BalanceDetails.Marketer,
        Transactions: fundsData.Transaction,
      }));
    } catch (error) {}
  };

  const fetchAllAdverts = async () => {
    try {
      const authToken = sessionStorage.getItem(`authToken`);

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/seller/advert/view/all`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );

      // Save the advert tasks to both session and userDataE
      sessionStorage.setItem(`advertTasks`, JSON.stringify(response.data.Data));
      setUserDataE((prevUserData) => ({
        ...prevUserData,
        AdvertTasks: response.data.Data,
      }));

      setUserData((prevUserData) => ({
        ...prevUserData,
        AdvertTasks: response.data.Data,
      }));
    } catch (error) {}
  };

  const fetchUserDataAndTasks = async () => {
    try {
      await fetchData();
      await fetchUserFunds();
      await fetchAllAdverts();
    } catch (error) {}
  };

  const initializeSocket = (roomIds) => {
    const newSocket = io(`${process.env.REACT_APP_API_URL}`, {
      query: { roomIds },
    });

    newSocket.on(`connect`, () => {});

    setSocket(newSocket);
  };

  const fetchChatRooms = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/marketplace/chatoperation/rooms`,
        {
          headers: {
            authorization: sessionStorage.getItem(`authToken`),
          },
        }
      );

      if (response.data.Access) {
        setChatRooms(response.data.Rooms);
        initializeSocket(response.data.Rooms.map((room) => room._id));
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const startChat = async (productId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/marketplace/chatoperation/start/${productId}`,
        {
          headers: {
            authorization: sessionStorage.getItem(`authToken`),
          },
        }
      );

      if (response.data.Access) {
        // Get the newly created chat room details
        const newChatRoom = response.data.RoomDetails;

        // Update the chat rooms in the state, add the new chat room
        setChatRooms((prevChatRooms) => [...prevChatRooms, newChatRoom]);

        // Set the selected room to the newly created chat room
        setSelectedRoom(newChatRoom);

        // If socket exists, emit `joinRoom` event for the new room
        if (socket) {
          socket.emit(`joinRoom`, newChatRoom._id);
        }
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    // Retrieve saved userData, advertTasks from session if available
    const savedUserData = sessionStorage.getItem(`userData`);
    const savedAdvertTasks = sessionStorage.getItem(`advertTasks`);
    const savedUserDataE = sessionStorage.getItem(`userDataE`);

    if (savedUserData) {
      setUserData(JSON.parse(savedUserData));
      setUserDataE(JSON.parse(savedUserData));
    }

    if (savedAdvertTasks) {
      setUserDataE((prevUserData) => ({
        ...prevUserData,
        AdvertTasks: JSON.parse(savedAdvertTasks),
      }));
    }

    if (savedUserDataE) {
      setUserDataE(JSON.parse(savedUserDataE));
    }

    // Use the new fetchUserDataAndTasks function
    fetchUserDataAndTasks();
    fetchChatRooms();
  }, []);

  const resendVerificationEmail = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/register/otp?email=${email}`
      );
      const data = response.data;
      if (data.Access) {
        if (data.Sent) {
          toast.success(`Email resent successfully`);
        } else {
          toast.error(`Resending email failed: Email not sent`);
        }
      } else {
        toast.error(data.Error || `Unknown error`);
      }
    } catch (error) {
      toast.error(error.response?.data?.Error || `Unknown error`);
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchProductPrices = async () => {
    try {
      const authToken = sessionStorage.getItem(`authToken`);

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/basicdata/app/prices`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );

      const data = response.data;

      if (data.Access) {
        // Save product prices to context
        setProductPrices(data.Data);

        // Save product prices to session storage
        sessionStorage.setItem(`productPrices`, JSON.stringify(data));
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    // Check if product prices are already stored in session storage
    const savedProductPrices = sessionStorage.getItem(`productPrices`);

    if (savedProductPrices) {
      // If available, set the product prices in the state
      setProductPrices(JSON.parse(savedProductPrices));
    } else {
      // If not available, fetch product prices
      fetchProductPrices();
    }
  }, []);

  return (
    <MyContext.Provider
      value={{
        someValue,
        setSomeValue,
        isLoading,
        setIsLoading,
        email,
        setEmail: setEmailState,
        authenticationData,
        setAuthenticationData: saveAuthenticationData,
        resendVerificationEmail,
        clientLogged,
        setClientLogged,
        isModalOpen,
        openModal,
        closeModal,
        setIsModalOpen,
        userData,
        userDataE, // Include userDataE in the context
        fetchUserFunds,
        advertTasks,
        fetchAllAdverts,
        fetchData,
        fetchUserDataAndTasks,
        chatRooms,
        startChat,
        fetchChatRooms,
        productPrices,
        setSelectedRoom,
        selectedRoom,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

const useMyContext = () => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error(`useMyContext must be used within a MyContextProvider`);
  }
  return context;
};

export { MyContextProvider, useMyContext };
