import React, { useState, useRef, useEffect } from "react";
import logo from "../../images/buzzy-logo.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { FaUser, FaBars, FaTimes } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { AiOutlineShop } from "react-icons/ai";
import Facebook from "../../images/Facebook.png";
import Whatsapp from "./images/WhatsApp.png";
import Instagram from "../../images/Instagram.png";
import Tiktok from "../../images/tiktok.svg";
import { useMyContext } from "../../context/MyContext";
import { Dropdown } from "flowbite-react";

const DashNavbar = ({ }) => {
  const navigate = useNavigate();
  const [nav, setNav] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const {
    isModalOpen,
    setModalOpen,
    openModal,
    closeModal,
    setIsLoading,
    userData,
  } = useMyContext();

  const userDataa = sessionStorage.getItem("userDataE");
  const UD = userDataa ? JSON.parse(userDataa) : [];

  const profilePhoto = UD.Profile;
  const [marketExpanded, setMarketExpanded] = useState(false)
  // const [activeLink, setActiveLink] = useState(null);

  const handleLogout = () => {
    window.sessionStorage.clear();
    window.localStorage.clear();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      navigate("/login");
    }, 4000);
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownButtonRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  const { pathname } = useLocation();

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleClick = () => setNav(!nav);
  const handleClose = () => setNav(!nav);

  useEffect(() => {
    const handleResize = () => {
      const newWindowWidth = window.innerWidth;
      setWindowWidth(newWindowWidth);
      if (newWindowWidth > 768) {
        setNav(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };

    // document.addEventListener("click", handleClickOutside);

    // return () => {
    //   document.removeEventListener("click", handleClickOutside);
    // };
  }, []);

  return (
    <div className="max-w-[100vw] bg-white py-1.5">
      <div className="w-[100%] md:w-[95%] xl:w-[95%] mx-auto h-[10vh] relative top-0 fixed bg-white border-b-2 md:border-b-0 ">
        <div className="w-full md:h-[10vh] flex grid md:grid-cols-5 gap-3">
          <div className=" flex items-center justify-between md:justify-start">
            <Link to={"/"}>
              <div className="flex items-center">
                <img src={logo} alt="Logo" className="w-13 h-14" />
                <h1 className="text-2xl text-blue-900 flex justify-center uppercase font-bold py-5">
                  Buzzy.ng
                </h1>
              </div>
            </Link>

            <div className="md:hidden flex items-center justify-between w-[45%]">
              <div className="w-28 mr-4 flex justify-around items-center h-[6vh] bg-[#FAFAFC] rounded-3xl px-2">
                <div className="relative flex items-center">
                  <a
                    href="/notifications"
                    class="flex md:p-2 items-center justify-center text-base font-medium leading-normal text-center align-middle transition-colors duration-150 ease-in-out bg-white shadow cursor-pointer rounded-2xl text-stone-500 border-stone-200 hover:text-primary active:text-primary focus:text-primary"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
                      ></path>
                    </svg>
                  </a>
                </div>

                <div className="relative flex items-center">
                  <a
                    href="/chat"
                    class="flex p-2 items-center justify-center text-base font-medium leading-normal text-center align-middle transition-colors duration-150 ease-in-out bg-white shadow cursor-pointer rounded-2xl text-stone-500 border-stone-200 hover:text-primary active:text-primary focus:text-primary"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div
                id="burger menu"
                className="md:hidden w-12"
                onClick={handleClick}
              >
                {!nav ? (
                  <FaBars className="w-12" />
                ) : (
                  <FaTimes className="w-12" />
                )}
              </div>
            </div>
          </div>

          {windowWidth > 768 ? (
            // Desktop View
            <div className="w-full grid md:grid-cols-5 gap-3 md:w-[100%] col-span-4">
              <div className="flex md:mt-4 items-center h-[6vh] lg:bg-[#FAFAFC] rounded-3xl lg:col-span-2 lg:w-[90%]">
                <input
                  class=" w-full bg-transparent px-4 py-1 text-gray-800 outline-none focus:outline-none border-none"
                  type="search"
                  name="search"
                  placeholder="Search Products/Services"
                />
                <button type="submit" class="m-2 rounded px-4 py-1 text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="20"
                    height="20"
                    viewBox="0 0 50 50"
                  >
                    <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"></path>
                  </svg>
                </button>
              </div>

              <div className="flex flex-col justify-around items-center md:flex-row px-5 lg:bg-[#FAFAFC] rounded-3xl md:mt-4 h-[6vh] ml-5 lg:col-span-2 lg:w-[90%]border border-red-800 bg-gray-500 ">
                <NavLink
                  className={`py-1 px-2 my-4 transition duration-200 hover:text-blue-900 text-gray-500 active:text-blue-700`}
                  exact
                  to="/"
                >
                  {({ isActive, isPending, isTransitioning }) => (
                    <p className={isActive ? "text-blue-700" : ""}>Home</p>
                  )}
                </NavLink>
                <NavLink
                  className={`py-1 px-2 my-4 transition duration-200 hover:text-blue-900 text-gray-500 active:text-blue-700`}
                  to="/earn"
                >
                  {({ isActive, isPending, isTransitioning }) => (
                    <p className={isActive ? "text-blue-700" : ""}>Earn</p>
                  )}
                </NavLink>
                <Link
                  className={`py-1 px-1 my-4 transition duration-200 hover:text-blue-900 ${pathname === "/social/buy/pricing"
                    ? " text-blue-700"
                    : "text-gray-500"
                    }`}
                  onClick={() => {
                    openModal();
                  }}
                >
                  <p>Advertise</p>
                </Link>
                <NavLink>
                  <Dropdown
                    size="sm"
                    label="Market"
                    renderTrigger={({ isActive }) => (
                      <span>
                        <p className={isActive ? "text-blue-700" : ""}>
                          Market
                        </p>
                      </span>
                    )}
                    dismissOnClick={true}
                  >
                    <Dropdown.Item>
                      <NavLink
                        className={`w-[100%] px-2 flex items-center transition duration-200 hover:text-blue-900 text-base font-[Montserrat] font-medium active:text-blue-700`}
                        to="/market"
                      >
                        {({ isActive }) => (
                          <p
                            className={
                              isActive ? "text-blue-700 text-center" : ""
                            }
                          >
                            Shop
                          </p>
                        )}
                      </NavLink>
                    </Dropdown.Item>
                    {/* <Dropdown.Item>
                      <NavLink
                        className={`w-[100%] px-2 flex items-center transition duration-200 hover:text-blue-900 text-base font-[Montserrat] font-medium active:text-blue-700`}
                        to="/events"
                      >
                        {({ isActive }) => (
                          <p
                            className={
                              isActive ? "text-blue-700 text-center" : ""
                            }
                          >
                            Events
                          </p>
                        )}
                      </NavLink>
                    </Dropdown.Item> */}
                    <Dropdown.Item>
                      <NavLink
                        className={`w-[100%] px-2 flex items-center transition duration-200 hover:text-blue-900 text-base font-[Montserrat] font-medium active:text-blue-700`}
                        to="/vote"
                      >
                        {({ isActive }) => (
                          <p
                            className={
                              isActive ? "text-blue-700 text-center" : ""
                            }
                          >
                            Vote
                          </p>
                        )}
                      </NavLink>
                    </Dropdown.Item>
                  </Dropdown>
                </NavLink>

                {/* <div className="relative group">
                  <div className="flex items-center cursor-pointer">
                    <NavLink
                      className={`py-1 px-2 my-4 transition duration-200 hover:text-blue-900 text-gray-500 active:text-blue-700`}
                      to="/market"
                    >
                      {({ isActive, isPending, isTransitioning }) => (
                        <p className={isActive ? "text-blue-700" : ""}>
                          Market
                        </p>
                      )}
                    </NavLink>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 ml-1 transform group-hover:rotate-180 transition-transform duration-200"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                  <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50">
                    <Link
                      to="/market"
                      className="block px-4 py-2 text-sm text-gray-700 hover:text-blue-500"
                    >
                      Shop
                    </Link>
                    <Link
                      to="/events"
                      className="block px-4 py-2 text-sm text-gray-700 hover:text-blue-500"
                    >
                      Events
                    </Link>
                  </div>
                </div> */}
              </div>

              <div className="flex justify-evenly items-center">
                <div className=" md:w-32 flex justify-around items-center h-[6vh] bg-[#FAFAFC] rounded-3xl px-2">
                  <div className="relative flex items-center">
                    <NavLink
                      to="/notifications"
                      class="flex md:p-2 items-center justify-center text-base font-medium leading-normal text-center align-middle transition-colors duration-150 ease-in-out bg-white shadow cursor-pointer rounded-2xl text-stone-500 border-stone-200 hover:text-primary active:text-primary focus:text-primary"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
                        ></path>
                      </svg>
                    </NavLink>
                  </div>

                  <div className="relative flex items-center">
                    <NavLink
                      to="/chat"
                      href="javascript:void(0)"
                      class="flex p-2 items-center justify-center text-base font-medium leading-normal text-center align-middle transition-colors duration-150 ease-in-out bg-white shadow cursor-pointer rounded-2xl text-stone-500 border-stone-200 hover:text-primary active:text-primary focus:text-primary"
                    >
                      <svg
                        class="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                        ></path>
                      </svg>
                    </NavLink>
                  </div>
                </div>

                <div className="relative inline-block text-left">
                  <button
                    ref={dropdownButtonRef}
                    className="inline-flex capitalize justify-between items-center w-full px-2 py-3 text-sm font-normal text-gray-700 bg-white rounded-sm focus:outline-none"
                    onClick={toggleDropdown}
                  >
                    <div className="rounded-full focus:outline-none focus:ring">
                      <img
                        className="object-contain w-12 rounded-full"
                        src={profilePhoto}
                        alt="Ahmed Kamel"
                      />
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <div
                    ref={dropdownMenuRef}
                    className={`origin-top-right absolute right-0 mt-2 w-48 rounded-md z-40 shadow-md lg:bg-[#fafafa] ring-2 ring-black ring-opacity-10 ${isDropdownOpen ? "" : "hidden"
                      }`}
                  >
                    <div
                      className="py-1 p-2"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="dropdown-button"
                    >
                      <nav className="w-full pb-3">
                        <Link
                          className={`flex items-center py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${pathname === "/tasks"
                            ? " bg-blue-900 text-white"
                            : "text-gray-500"
                            }`}
                          to="/tasks"
                          onClick={!toggleDropdown}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="currentColor"
                            class="bi bi-columns-gap"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 1v3H1V1zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm14 12v3h-5v-3zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM6 8v7H1V8zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1zm14-6v7h-5V1zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1z" />
                          </svg>
                          <p className="font-normal text-base px-5">Tasks</p>
                        </Link>

                        <Link
                          className={`flex items-center py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${pathname === "/orders"
                            ? " bg-blue-900 text-white"
                            : "text-gray-500"
                            }`}
                          to="/orders"
                          onClick={!toggleDropdown}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="currentColor"
                            class="bi bi-file-earmark-check"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                          </svg>
                          <p className="font-normal text-base px-5">
                            My Orders
                          </p>
                        </Link>

                        <Link
                          className={`flex items-center py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${pathname === "/wallet"
                            ? " bg-blue-900 text-white"
                            : "text-gray-500"
                            }`}
                          to="/wallet"
                          onClick={!toggleDropdown}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="currentColor"
                            class="bi bi-wallet"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1" />
                          </svg>
                          <p className="font-normal text-base px-5">Wallet</p>
                        </Link>

                        <Link
                          className={`flex items-center py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${pathname === "/chat"
                            ? " bg-blue-900 text-white"
                            : "text-gray-500"
                            }`}
                          to="/chat"
                          onClick={!toggleDropdown}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="currentColor"
                            class="bi bi-chat-dots"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                            <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2" />
                          </svg>
                          <p className="font-normal text-base px-5">Chat</p>
                        </Link>

                        <Link
                          className={`flex items-center py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${pathname === "/my-product"
                            ? " bg-blue-900 text-white"
                            : "text-gray-500"
                            }`}
                          to="/my-product"
                          onClick={handleClose}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="currentColor"
                            class="bi bi-bell"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                          </svg>
                          <p className="font-normal text-[15px] px-5">
                            My Product
                          </p>
                        </Link>

                        <Link
                          className={`flex items-center py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${pathname === "/settings"
                            ? " bg-blue-900 text-white"
                            : "text-gray-500"
                            }`}
                          to="/settings"
                          onClick={!toggleDropdown}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="currentColor"
                            class="bi bi-gear"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                          </svg>
                          <p className="font-normal text-base px-5">Settings</p>
                        </Link>

                        <button
                          className="mt-12 py-2 text-base bg-blue-800 hover:bg-blue-700 cursor-pointer text-white w-full rounded"
                          onClick={handleLogout}
                        >
                          Logout
                        </button>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          ) : (
            <div
              className={
                !nav ? "hidden" : "w-[100%] h-screen flex justify-end"
              }
            >
              <div
                className="backdrop w-[100%] z-0 h-screen flex justify-end"
                onClick={handleClose}
              ></div>
              <div
                className="fixed top-0 w-48 z-10 h-screen shadow-sm bg-white ring-opacity-5 p-2 overflow-y-auto"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="dropdown-button"
              >
                <div>
                  <div className="flex items-center border-b-2">
                    <img src={logo} alt="Logo" className="w-13 h-14" />
                    <h1 className="text-2xl text-[#405AC6] flex justify-center uppercase font-bold py-5">
                      Buzzy.ng
                    </h1>
                  </div>
                </div>

                <nav className="w-full pb-3 border-b-2">
                  <Link
                    className={`flex items-center py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${pathname === "/"
                      ? " bg-blue-900 text-white"
                      : "text-gray-500"
                      }`}
                    to="/"
                    onClick={handleClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="currentColor"
                      class="bi bi-house-door"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z" />
                    </svg>

                    <p className="font-normal text-base px-5">Home</p>
                  </Link>

                  <Link
                    className={`flex items-center py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${pathname === "/earn"
                      ? " bg-blue-900 text-white"
                      : "text-gray-500"
                      }`}
                    to="/earn"
                    onClick={handleClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="currentColor"
                      class="bi bi-coin"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z" />
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12" />
                    </svg>
                    <p className="font-normal text-base px-5">Earn</p>
                  </Link>

                  <Link
                    className={`flex items-center py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${pathname === "/social/buy/pricing"
                      ? " bg-blue-900 text-white"
                      : "text-gray-500"
                      }`}
                    onClick={() => {
                      openModal();
                      handleClose();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="currentColor"
                      class="bi bi-megaphone"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 75 75 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0m-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233q.27.015.537.036c2.568.189 5.093.744 7.463 1.993zm-9 6.215v-4.13a95 95 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A61 61 0 0 1 4 10.065m-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68 68 0 0 0-1.722-.082z" />
                    </svg>
                    <p className="font-normal text-base px-5">Advertise</p>
                  </Link>

                  <div
                    onClick={() => setMarketExpanded(!marketExpanded)}
                  >
                    <Link
                      className={`flex items-center py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${pathname === "/market" ? " bg-blue-900 text-white" : "text-gray-500"
                        }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        fill="currentColor"
                        class="bi bi-cart"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                      </svg>
                      <p className="font-normal text-base px-5">Market</p>
                    </Link>
                    {marketExpanded && (
                      <div className="ml-8">
                        <Link
                          to="/market"
                          className="block py-2 text-sm text-gray-700 hover:text-blue-500"
                          onClick={handleClose}
                        >
                          Shop
                        </Link>
                        {/* <Link
                          to="/events"
                          className="block py-2 text-sm text-gray-700 hover:text-blue-500"
                          onClick={handleClose}
                        >
                          Events
                        </Link> */}
                        <Link
                          to="/vote"
                          className="block py-2 text-sm text-gray-700 hover:text-blue-500"
                          onClick={handleClose}
                        >
                          Vote
                        </Link>
                      </div>
                    )}
                  </div>
                </nav>

                <nav className="w-full pb-3">
                  <p className="text-base text-[#aaa] font-normal px-4 my-4">
                    Account
                  </p>

                  <Link
                    className={`flex items-center py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${pathname === "/tasks"
                      ? " bg-blue-900 text-white"
                      : "text-gray-500"
                      }`}
                    to="/tasks"
                    onClick={handleClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="currentColor"
                      class="bi bi-columns-gap"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6 1v3H1V1zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm14 12v3h-5v-3zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM6 8v7H1V8zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1zm14-6v7h-5V1zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1z" />
                    </svg>
                    <p className="font-normal text-base px-5">Tasks</p>
                  </Link>

                  <Link
                    className={`flex items-center py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${pathname === "/orders"
                      ? " bg-blue-900 text-white"
                      : "text-gray-500"
                      }`}
                    to="/orders"
                    onClick={handleClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="currentColor"
                      class="bi bi-file-earmark-check"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                    </svg>
                    <p className="font-normal text-base px-5">My Order</p>
                  </Link>

                  <Link
                    className={`flex items-center py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${pathname === "/wallet"
                      ? " bg-blue-900 text-white"
                      : "text-gray-500"
                      }`}
                    to="/wallet"
                    onClick={handleClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="currentColor"
                      class="bi bi-wallet"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1" />
                    </svg>
                    <p className="font-normal text-base px-5">Wallet</p>
                  </Link>

                  <Link
                    className={`flex items-center py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${pathname === "/chat"
                      ? " bg-blue-900 text-white"
                      : "text-gray-500"
                      }`}
                    to="/chat"
                    onClick={handleClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="currentColor"
                      class="bi bi-chat-dots"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                      <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2" />
                    </svg>
                    <p className="font-normal text-base px-5">Chat</p>
                  </Link>

                  <Link
                    className={`flex items-center py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${pathname === "/my-product"
                      ? " bg-blue-900 text-white"
                      : "text-gray-500"
                      }`}
                    to="/my-product"
                    onClick={handleClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="currentColor"
                      class="bi bi-bell"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                    </svg>
                    <p className="font-normal text-base px-5">My Product</p>
                  </Link>

                  <Link
                    className={`flex items-center py-2.5 px-4 my-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${pathname === "/settings"
                      ? " bg-blue-900 text-white"
                      : "text-gray-500"
                      }`}
                    to="/settings"
                    onClick={handleClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="currentColor"
                      class="bi bi-gear"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                      <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                    </svg>
                    <p className="font-normal text-base px-5">Settings</p>
                  </Link>

                  <button
                    className="mt-12 py-2 text-[18px] bg-blue-800 hover:bg-blue-700 cursor-pointer text-white w-full rounded"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>

      <div>
        {isModalOpen && (
          <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center p-48 ">
            <div className="bg-gray-200 lg:p-8 md:p-8 rounded-xl shadow-lg relative flex flex-col">
              <div className="flex flex-row justify-center items-center px-16">
                <FaArrowLeft
                  onClick={closeModal}
                  className="text-2xl cursor-pointer mr-8 absolute left-0 ml-4
                "
                />
                <div className="lg:text-2xl md:text-xl sm:text-lg text-base font-bold text-center text-black">
                  What do you want?
                </div>
              </div>

              <div className=" flex flex-row justify-between lg:mt-4 md:mt-4 lg:flex-nowrap md:flex-wrap sm:flex-wrap flex-wrap scale-75">
                <div className="bg-white p-4 rounded-lg mx-1 mr-4 flex flex-col justify-center items-center px-10 my-2">
                  <p className="lg:text-lg  font-semibold my-4 text-black">
                    Promote on Buzzy market
                  </p>
                  <AiOutlineShop className="lg:text-6xl text-3xl text-indigo-900 " />
                  <p className="text-black lg:text-lg md:text-base sm:text-sm text-xs max-w-4xl text-center mt-8 mb-4">
                    Advertise on Buzzy Market: Promote your products or services
                    directly on Buzzy Market, reaching a targeted audience of
                    engaged users.
                  </p>
                  <Link to="/market/sell">
                    <button
                      className="border-indigo-900 border-2 w-56 mt-4 p-2 text-indigo-900 rounded-lg"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      Get started
                    </button>
                  </Link>
                </div>
                <div className="bg-white p-4 rounded-lg mx-1 mr-4 flex flex-col justify-center items-center px-10 my-2">
                  <p className="text-lg font-semibold my-4 text-black">
                    Promote on Social Media
                  </p>
                  <div className="flex justify-center items-center mt-4">
                    <div className=" lg:w-10 w-6">
                      <img src={Facebook} alt="Facebook" />
                    </div>
                    <div className="  lg:w-10 w-6">
                      <img src={Whatsapp} alt="Whatsapp" />
                    </div>
                    <div className=" lg:scale-125 mr-2 scale-95">
                      <img src={Instagram} alt="Instagram" />
                    </div>
                    <div className="lg:w-8 w-4">
                      <img src={Tiktok} alt="Tiktok" className="rounded-lg" />
                    </div>
                  </div>
                  {/* <AiOutlineShop className="text-6xl text-indigo-900 " /> */}
                  <p className="text-black lg:text-lg md:text-base sm:text-sm text-xs max-w-4xl text-center mt-8 mb-4">
                    Advertise on Social Media: Utilize targeted campaigns to
                    boost brand awareness, engagement, and conversions.
                  </p>
                  <Link to="/social/buy/pricing">
                    <button
                      className="border-indigo-900 border-2 w-56 mt-4 p-2 text-indigo-900 rounded-lg"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      Get started
                    </button>
                  </Link>
                </div>
              </div>
              {/* <button
              onClick={closeModal}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
            >
              Close Modal
            </button> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashNavbar;