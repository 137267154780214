import React from "react";
import SignUpImage from "../images/image-5.png";
import SignUpForm1 from "./SignUpForm1";
import VisitorsHeader from "../component/vistorsheader";
import Bottom from "../component/bottom-section";
import Footer from "../component/footer";
import { useParams } from "react-router-dom";

const Register1 = () => {
  let { code } = useParams();
  return (
    <div>
      <VisitorsHeader />
      <>
        <div className="flex py-4 my-3 flex-col lg:flex-row min-h-screen px-8 justify-between">
          {/* Left side with image */}
          <div className="lg:w-1/2 md:hidden hidden lg:block ">
            <img
              src={SignUpImage}
              alt="Sign Up"
              className="object-cover w-full h-full"
            />
          </div>

          {/* Right side with sign-up form and progress bar */}
          <div className="lg:w-1/2 flex items-center justify-center p-8 mt-4">
            <div className="max-w-md w-full">
              {/* Progress Bar */}

              <ul className="steps steps-vertical lg:steps-horizontal w-[100%] ">
                <li className="step step-primary"></li>
                <li className="step "></li>
                <li className="step"></li>
                <li className="step"></li>
              </ul>

              <SignUpForm1 code={code} />
            </div>
          </div>
        </div>
      </>
      <Bottom />
      <Footer />
    </div>
  );
};

export default Register1;
