import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DashNavbar from "../DashNavbar";
import { FaCamera, FaVideo } from "react-icons/fa";
import { LuWallet2 } from "react-icons/lu";
import { IoChevronBack } from "react-icons/io5";
import statesAndLGAs from "../../AllStateLga";
import axios from "axios";
import { useMyContext } from "../../../context/MyContext";
import { toast } from "react-toastify";

import { platformNames3 } from "./data";

 
const AdForm3 = () => {

  //turn first letter of word to capital
  function capitalizeWords(str) {
    return str.split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' ');
  }


  const navigate = useNavigate();
  const { platform } = useParams();
  const location = useLocation();
 
  const { isLoading, setIsLoading } = useMyContext();

  const savedUserDataString = sessionStorage.getItem("userData");
  const savedUserData = savedUserDataString
    ? JSON.parse(savedUserDataString)
    : null;

  const currentPlatform = platformNames3[platform];

  useEffect(() => {
    // If the platform ID is not valid, navigate to the 404 route
    if (!currentPlatform) {
      navigate("/404");
    }
  }, [navigate, currentPlatform]);

  const [showModal, setShowModal] = useState(false);

  const handleSubmission = () => {
    // Add logic for handling submission and making payments
    // For now, let's just open the modal
    setShowModal(true);
  };

  const [selectedPlatform, setSelectedPlatform] = useState(
    currentPlatform.name
  );

  const getPlatformRoute = (platformName) => {
    const platformRoutes = {
      Follow: "follow",
      Like: "like",
      facebook: "fblikesfollow",
      audiomack: "audiomackfollow",
      comment: "comment", 
      youtube_subscribe: "youtube_subscribe",
      youtube_likes_view_comment: "youtube_likes_view_comment",
      playStore: "playstore_download",
      appStore: "appstore_download",
      whatsapp: `join/Whatsapp`,
      telegram: `join/Telegram`,
      linkedin: "linkedin_follow",
      boomplay: "boomplay_follow",
      Views: "views",

      // Add other platforms as needed
    };

    return platformRoutes[platformName] || "";
  };

  const platformRoute = getPlatformRoute(selectedPlatform);

  const [platformSelect, setPlatformSelect] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [gender, setGender] = useState("");
  const [state, setState] = useState("");
  const [religion, setReligion] = useState("");
  const [interest, setInterest] = useState("All");
  const [caption, setCaption] = useState("");
  const [socialLink, setSocialLink] = useState("");

  const handleSubmissionPaid = () => {
    setIsLoading(true);
    // Construct the request payload
    const payload = {
      SocialLink:socialLink,
      Caption:caption,
      Quantity:quantity,
      Platform:platformSelect,
      Gender:gender,
      State:state,
      Religion:religion,
      Interest:interest
    };
    let url = `${process.env.REACT_APP_API_URL}/seller/engagment/addsocialtask/${platformRoute}`;
    // if (platformRoute.includes('join')==true) {
    //   url += `/${platformSelect}`;
    // }

    // Make the API call
    axios
      .post(url, payload, {
        headers: {
          authorization: sessionStorage.getItem("authToken"),
        },
      })
      .then((response) => {
        toast.success("Advert successfully created");
        navigate("/");
        // Handle the response as needed
      })
      .catch((error) => {
        toast.error(error.response.data.Error);
        // Handle errors
      })
      .finally(() => {
        setShowModal(false);
        setIsLoading(false);
        // Close the modal after API call is complete
      });
  };

  const handleSubmissionCommentPaid = () => {
    setIsLoading(true);
    // Construct the request payload
    const payload = {
      SocialLink:socialLink,
      Caption:caption,
      Quantity:quantity,
      Platform:platformSelect,
      Gender:gender,
      State:state,
      Religion:religion,
      Interest:interest
    };

    let url = `${process.env.REACT_APP_API_URL}/seller/engagment/addsocialtask/${platformRoute}`;
    

    // Make the API call
    axios
      .post(url, payload, {
        headers: {
          authorization: sessionStorage.getItem("authToken"),
        },
      })
      .then((response) => {
        toast.success("Engagment successfully created");
        navigate("/");
        // Handle the response as needed
      })
      .catch((error) => {
        toast.error(error.response.data.Error);
        // Handle errors
      })
      .finally(() => {
        setShowModal(false);
        setIsLoading(false);
        // Close the modal after API call is complete
      });
  };

  const savedprice = sessionStorage.getItem("productPrices");
  const Price = savedprice ? JSON.parse(savedprice) : null;

  const getCurrentPrice = () => {
    if (currentPlatform.name) {
      switch (selectedPlatform) {
        case "Like":
          return Price.Data.Likes;
        case "Follow":
          return Price.Data.Followers;
        case "facebook":
          return Price.Data.Like_Follow_FB_Page;
        case "audiomack":
          return Price.Data.Audiomack_follow;
        case "comment":
          return Price.Data.Comment;
        case "youtube_subscribe":
          return Price.Data.Youtube_Subscribe;
        case "Views":
          return Price.Data.Views;
        case "playStore":
          return Price.Data.Playstore_download;
        case "appStore":
          return Price.Data.Appstore_download;
        case "whatsapp":
          return Price.Data.Join;
        case "telegram":
          return Price.Data.Join;
        case "linkedin":
          return Price.Data.LinkedIn_follow;
        case "boomplay":
          return Price.Data.Boomplay_follow;
        // Add cases for other platforms as needed
        default:
          return 0; // Default price if platform not found
      }
    }
    return 0; // Default price if currentPlatform is not available
  };

  // Calculate the current price
  const currentPrice = getCurrentPrice();

  return (
    <div className="px-3 md:px-10">
      <DashNavbar />
      <div className="grid w-full pt-[3%] md:pt-[1%]">
        <main className="bg-[#FAFAFc] rounded-2xl flex-1 overflow-y-auto p-5">
          <div>
            {/* <h2>AdForm3 for platform: {currentPlatform.name}</h2> */}
            <div className="flex justify-center my-4">
              <div className="flex justify-center items-center">
                {/* {platformRoute} */}
                <img
                  src={currentPlatform.image}
                  alt={`${currentPlatform.name}-logo`}
                  className="w-96 h-72 my-2 max-w-full"
                  style={{ height: "auto" }}
                />
              </div>

              <div className="right">
                <h2 className="text-lg text-black font-semibold my-0.5">
                  {currentPlatform.header}
                </h2>
                <span className="text-xs text-black font-semibold">
                  Pricing: EDO{currentPrice} per {currentPlatform.name} Advert
                  Post
                </span>
                <div className="h-0.5 w-full my-2 bg-gray-400"></div>
                <div className="flex flex-col">
                  <span className="text-xs font-medium text-black">
                    {currentPlatform.text1}
                  </span>
                </div>
                <div className="flex my-2 items-center">
                  <h2 className="text-sm text-black font-semibold mr-1">
                    Platform:
                  </h2>
                  {currentPlatform.platform.map((platform, index) => (
                    <img
                      key={index}
                      src={platform}
                      alt={`${currentPlatform.name}-platform-${index}`}
                      className="h-6 w-6 mr-0.5"
                    />
                  ))}
                </div>
              </div>
            </div>

            {[
              "fblikesfollow",
              "audiomackfollow",
              "tiktokview",
              "joinwhatsapchannel",
            ].includes(platformRoute) ? null : (
              <>
                <div className="flex my-4">
                  <h2 className="text-sm text-black font-semibold mr-2">
                    Platform:
                  </h2>
                </div>
                <div className="border-b border-gray-400 w-full mt-2">
                  <select
                    className="w-full border-none focus:outline-none text-black"
                    value={platformSelect}
                    onChange={(e) => setPlatformSelect(e.target.value)}
                  >
                    <option value="" disabled>
                      Select platform
                    </option>
                    {currentPlatform.platformNames.map((platform, index) => (
                      <option
                        key={index}
                        value={platform=="TikTok"?"TikTok":platform=="LinkedIn"?"LinkedIn":capitalizeWords(platform)}
                        className="uppercase"
                      >
                        {platform=='ig'?"Instagram":platform=='x'?"X":platform=='tiktok'?"Tiktok":platform=="fb"?"Facebook":capitalizeWords(platform)}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}

            <div className="flex my-4 mt-8">
              <h2 className="text-sm text-black font-semibold mr-2">
                Number of Engagements Posts You Want:
              </h2>
            </div>

            
            <div className="border-b border-gray-400 w-full mt-2">
              <input
                type="number"
                className="w-full border-none focus:outline-none text-black"
                placeholder="Enter the number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>


            {/* comment  */}
            {/* {platformRoute.includes('join')==false?( */}

              <div>

                {/* Gender  */}
                <div className="flex my-4 mt-8">
                  <h2 className="text-sm text-black font-semibold mr-2">
                  Gender:
                  </h2>
                </div>
                <div className="border-b border-gray-400 w-full mt-2">
                  <select name="gender" className="w-full border-none focus:outline-none text-black"
                    placeholder="Enter the number"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="" disabled selected>Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="All">All</option>
                  </select>
                  
                </div>

                {/* State */}
                <div className="flex my-4 mt-8">
                  <h2 className="text-sm text-black font-semibold mr-2">
                  State:
                  </h2>
                </div>
                <div className="border-b border-gray-400 w-full mt-2">
                  <select name="gender" className="w-full border-none focus:outline-none text-black"
                    placeholder="Enter the number"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    >
                     <option value="" disabled>
                          Select State
                        </option>
                        <option value="All">All Nigeria</option>
                        {statesAndLGAs.map((stateData) => (
                          <option key={stateData.state} value={stateData.state}>
                            {stateData.state}
                          </option>
                        ))}
                  </select>
                  
                </div>
                <div className="flex my-4 mt-8">
              <h2 className="text-sm text-black font-semibold mr-2">
                Interest:
              </h2>
            </div>
            <div className="border-b border-gray-400 w-full mt-2">
              <select
                className="w-full border-none focus:outline-none text-black"
                value={interest}
                onChange={(e) => setInterest(e.target.value)}
              >
                <option value="" disabled>
                  Select Interest
                </option>
                <option value="All">All</option>
                <option value="Fashion">Fashion</option>
                <option value="Comedy">Comedy</option>
                <option value="Short Skits">Short Skits</option>
                <option value="Movies">Movies</option>
                <option value="Books">Books</option>
                <option value="Shows">Shows</option>
                <option value="Music">Music</option>
                <option value="Politics">Politics</option>
                <option value="Food">Food</option>
                <option value="Cars">Cars</option>
                <option value="Bike">Bike</option>
                <option value="Trade Fair">Trade Fair</option>
                <option value="Personality">Personality</option>
                <option value="Film">Film</option>
              </select>
            </div>
                {/* Religion */}
                <div className="flex my-4 mt-8">
                  <h2 className="text-sm text-black font-semibold mr-2">
                  Religion:
                  </h2>
                </div>
                <div className="border-b border-gray-400 w-full mt-2">
                  <select name="gender" className="w-full border-none focus:outline-none text-black"
                    placeholder="Enter the number"
                    value={religion}
                    onChange={(e) => setReligion(e.target.value)}
                    >
                      <option disabled selected>Select Religion</option>
                     <option value="Christain">Christian</option>
                      <option value="Muslim">Muslim</option>
                      <option value="All">All</option>
                  </select>
                  
                </div>

                {/* Caption */}
                <div className="flex my-4 mt-8">
                  <h2 className="text-sm text-black font-semibold mr-2">
                  Extra guide (not required):
                  </h2>
                </div>
                <div className="border-b border-gray-400 w-full mt-2">
                <input
                type="text"
                className="w-full border-none focus:outline-none text-black"
                placeholder="Enter the caption"
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
                  
                </div>
                <p className="text-xs text-gray-300 mt-2">
                        Bit of information about how you want users to engage e.g caption type
                </p>
              </div>
            {/* ):null} */}
            

            <div className="flex my-4 mt-8">
              <h2 className="text-sm text-black font-semibold mr-2">
                {currentPlatform.text2}
              </h2>
            </div>
            <div className="border-b border-gray-400 w-full mt-2">
              <input
                type="text"
                className="w-full border-none focus:outline-none text-black"
                placeholder="..."
                value={socialLink}
                onChange={(e) => setSocialLink(e.target.value)}
                // Add any additional props as needed
              />
            </div>
            <p className="text-xs text-gray-300 mt-2">
              Please enter link to page
            </p>
            <div className="flex flex-row justify-between items-center">
              <div>
                <div className="text-xs text-black mt-8">You will pay</div>
                <h1 className=" font-bold text-black text-3xl ">
                  EDO {quantity * currentPrice}
                </h1>
              </div>
              <div>
                <button
                  onClick={handleSubmission}
                  className="bg-blue-900 text-white flex items-center px-4 py-2 rounded mt-8"
                >
                  Submit and make payments
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center bg-black bg-opacity-50 justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-md mx-auto my-6">
            {/* Modal content */}
            <div className="relative flex flex-row justify-center items-center w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none p-6 px-1 pr-20">
              <div className="px-2 p-4 absolute left-0 top-0">
                {/* Back arrow icon */}
                <IoChevronBack
                  onClick={() => setShowModal(false)}
                  className="cursor-pointer text-lg text-black"
                />
              </div>
              <div className="px-2 p-4">
                <LuWallet2 className="text-5xl text-black" />
              </div>
              <div className="flex flex-col items-start ml-3">
                <h1 className="font-bold text-2xl text-black">
                  Pay with your Wallet
                </h1>
                <p className="text-xs mb-8 text-black">
                  Wallet Balance:{" "}
                  <span className="text-black font-semibold">
                    EDO{" "}
                    {savedUserData && savedUserData.Funds
                      ? savedUserData.Funds.$numberDecimal
                      : "0.00"}
                  </span>
                </p>
                <button
                  onClick={platformRoute=='comment'?handleSubmissionCommentPaid:handleSubmissionPaid}
                  className="bg-blue-900 text-white flex items-center px-4 py-2 rounded"
                >
                  Pay now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default AdForm3;
