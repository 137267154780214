import React, { useState, useEffect } from "react";
import DashNavbar from "../../DashNavbar";
import DSTV from "../../images/dstv.png";
import GOTV from "../../images/gotv.png";
import Startimes from "../../images/Startimes.png";

// import Modal from "./Modal"
import axios from "axios";
import { toast } from "react-toastify";
import { useMyContext } from "../../../../context/MyContext";
import { MdOutlineCancel } from "react-icons/md";

const PayTv = () => {
  const [network, setNetwork] = useState("");
  const [TVPlan, setTVPlan] = useState("");
  const [packageName, setpackageName] = useState('')
  const [PricePlan, setPricePlan] = useState(0)
  const [SingleItemPrice, setSingleItemPrice] = useState(0)
  const [AccountNumber, setAccountNumber] = useState("");
  const [Period, setPeriod] = useState(1)
  // const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [plans, setPlans] = useState([]);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [networkCode, setnetworkCode] = useState("")
  const [customer, setcustomer] = useState({name:"",number:""})
  const { setIsLoading } = useMyContext();

  const [ButtonSubmit, setButtonSubmit] = useState(true)

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/wallet/paytv/get-plans`,
          {
            method: "GET",
            headers: {
              authorization: sessionStorage.getItem("authToken"),
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch tv plans");
        }

        const responseData = await response.json();
        // //console.log(responseData.Data.data);
        setPlans(responseData.Data);
      } catch (error) {
        //console.error(error.message);
        fetchData()
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    // Filter plans based on selected network

    // {
    //   "Multichoice DSTV":30,
    //   'Multichoice GOTV':40,
    //   'Startimes':70
    // }
    

    if (network) {
      if (network=="dstv"){ 
        setnetworkCode(30)
        setFilteredPlans(plans.MultichoiceDSTVData);
      }
      if (network=="gotv"){ 
        setnetworkCode(40)
        setFilteredPlans(plans.MultichoiceGOTVData);
      }
      if (network=="startimes"){ 
        setnetworkCode(70)
        setFilteredPlans(plans.StartimesData);
      }
    

      
    } else {
      setFilteredPlans([]);
    }
  }, [network, plans]);

  //run api to get customer name
  useEffect(() => {
    setcustomer({name:"",number:""})
    if(network=="dstv"&&AccountNumber.length==10){ 
      handleResolveCustomer()
    }else if(network=="gotv"&&AccountNumber.length==10){
      handleResolveCustomer()
    }else if(network=="startimes"&&AccountNumber.length==10){
      handleResolveCustomer()
    }else{
      setButtonSubmit(true)
    }

    // setButtonSubmit(true)
  }, [AccountNumber]);

  // const handleDataTypeChange = (e) => {
  //   setDataType(e.target.value);
  // };

  const handleNetworkChange=(e)=>{
    setNetwork(e.target.value)
    setAccountNumber("")
  }

  const handleTVPlanChange = (e) => {
    let thepack=(filteredPlans.find(i=>i.code==e.target.value))
    setTVPlan(e.target.value);
    setpackageName(thepack.name)
    setPricePlan(thepack?.Amount)
    setSingleItemPrice(thepack?.Amount)
    setAccountNumber("")
    setPeriod(1)

  };

  const handleAccountNumberChange = (e) => {
    setAccountNumber(e.target.value);
  };

  const handlePeriodChange = (e) => {
    setPeriod(e.target.value);
    setPricePlan(SingleItemPrice*e.target.value)
    setAccountNumber("")
  };

  const openModal = () => {
    setIsPasswordModalOpen(true);
  };

  const closeModal = () => {
    setIsPasswordModalOpen(false);
    setPassword(""); // Clear password field on modal close
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const sendData = async () => {
    // setIsLoading(true)
    setButtonSubmit(true)
    try {

//       - `Network`: The network provider for the data package transaction (string).
// - `AccountNumber`: The recipient's meter numberr (string).
// - `PackageName`: The tv package details (string).
// - `PackageCode`: The tv package Code (string)
// - `Amount`: The amount to be charged for the data package (number). NOTE: This would be automatically filled depending on the data package the User Selects.
// - `Period`: The duration for the package
// - `Customername`: The Customer name
// - `Custom
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/wallet/paytv/send/plan`,
        {
          Network: networkCode,
          AccountNumber: AccountNumber,
          Amount: PricePlan,
          PackageName:packageName,
          PackageCode:TVPlan,
          Period,
          Customername:customer.name,
          Customernumber:customer.number
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: sessionStorage.getItem("authToken"),
          },
        }
      );
      setIsPasswordModalOpen(true)

      if (response.data) {

        //console.log("Airtime sent successfully:", response.data);
        toast.success("TV bills request successfully");
      } else {
        //console.error("Error sending Data:", response.data);
        toast.error("Error sending Tv bills. Please try again.");
      }
    } catch (error) {
      //console.error("Error sending Data:", error.message);
      toast.error(error?.response?.data?.Error?error.response.data.Error:"Error sending Tv bills. Please try again.");
      
    }finally{
      setButtonSubmit(false)
      // setIsLoading(false)
    }
  };

  const handleResolveCustomer = async () => {
    // setIsLoading(true)
    try {
      setcustomer({
        name:"loading",
        number:"..."
      })

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/wallet/paytv/resolvecustomer?AccountNumber=${AccountNumber}&Network=${networkCode}`,
        
        {
          headers: {
            "Content-Type": "application/json",
            authorization: sessionStorage.getItem("authToken"),
          },
        }
      );

      console.log(response.data);

      setcustomer({
        name:response.data.Data.name,
        number:response.data.Data.customernumber
      })
      setButtonSubmit(false)
    } catch (error) {
      console.log(error);
      setcustomer({
        name:"Invalid smart card number",
        number:""
      })
      //console.error("Error sending Data:", error.message);
      // toast.error("Error sending Data. Please try again.");
    }finally{
      // setIsLoading(false)
    }
  };

  const handlePurchase = async () => {
    await sendData()//send data api
    // openModal(); // Open password modal
  };

  const handlePurchaseWithPassword = async () => {
    setIsLoading(true)
    try {
      const verifyResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/wallet/paytv/verify`,
        {
          password: password,
        },
        {
          headers: {
            authorization: `${sessionStorage.getItem("authToken")}`,
          },
        }
      );

      if (verifyResponse.data.Access) {
        toast.success("Data sent successfully");
        
      } else {
        //console.error("Password verification failed");
        toast.error("Password verification failed");
        // Handle password verification failure
      }
    } catch (error) {
      //console.error("Error purchasing data:", error.request.data);
      //console.log(error);
      toast.error(error?.response?.data?.Error?error.response.data.Error:"Error sending TV bills. Please try again.");

      // Handle error
    }finally{
      setIsLoading(false)
      setIsPasswordModalOpen(false)
    }
  };

  return (
    <div className="px-3 md:px-10">
      <DashNavbar />
      {isPasswordModalOpen&&(
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg flex flex-col p-3">
                <div className="flex flex-row justify-between my-1">
                  <p className="text-sm text-[#213481]">Confirm Transaction</p>
                  <MdOutlineCancel
                    className="text-[#7A0202] cursor-pointer"
                    onClick={closeModal}
                  />
                </div>

                <p className="lg:text-2xl md:text-xl text-lg font-semibold mb-2 text-[#213481]">
                  Continue?
                </p>
                <input
                  type="password"
                  className="input input-bordered border-[#213481] mb-4"
                  placeholder="Enter your password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <button
                  className="text-white btn bg-[#213481]"
                  onClick={handlePurchaseWithPassword}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
      <div className="grid w-full pt-[3%] md:pt-[1%]">
        <main className="bg-[rgb(250,250,252)] rounded-2xl flex-1 overflow-y-auto p-5">
          <div>
            <div className="flex items-center justify-center text-2xl flex-col">
              <p className="text-[#213481] font-semibold">
                Pay your bills
              </p>
              <p className="text-[#213481] font-bold text-4xl">Buy TV Bills</p>
            </div>

            <div className="flex flex-wrap justify-center items-center lg:justify-around mt-12 md:justify-between flex-row">
              <img src={DSTV} className="m-2 lg:w-auto md:w-auto w-11" alt="" />
              <img
                src={GOTV}
                className="m-2 lg:w-auto md:w-auto w-11"
                alt=""
              />
              <img src={Startimes} className="m-2 lg:w-auto md:w-auto w-11" alt="" />
             
            </div>

            <div className="flex flex-col p-8">
            {/* network  */}
              <select
                className="select select-bordered m-6 my-3"
                value={network}
                onChange={handleNetworkChange}
              >
                <option value="">Service provider</option>
                <option value="dstv">Multichoice DSTV</option>
                <option value="gotv">Multichoice GOTV</option>
                <option value="startimes">Startimes</option>
                {/* <option value="9mobile">9MOBILE</option> */}
              </select>

              <>
               
               {/* plans  */}
                <select
                  className="select select-bordered m-6 my-3"
                  value={TVPlan}
                  onChange={handleTVPlanChange}
                >
                  <option value="">TV Plan</option>
                  {filteredPlans?.map((plan) => (
                    <option key={plan.code} value={plan.code}>
                      {plan.name} {plan.description}
                    </option>
                  ))}
                </select>
                {/* { Network, AccountNumber, PackageName, PackageCode,Amount, Period, Customername, Customernumber  } */}
                
                <input
                  type="number"
                  className="input input-bordered m-6 my-3"
                  min={1}
                  
                  placeholder="Duration "
                  // value={Period}
                  onChange={handlePeriodChange}
                />

                {/* price  */}
                <input
                  type="number"
                  className="input input-bordered m-6 my-3"
                  placeholder="Amount"
                  value={PricePlan}
                  disabled
                />

                <input
                  type="number"
                  className="input input-bordered m-6 my-3"
                  placeholder="UIC Number"
                  value={AccountNumber}
                  onChange={handleAccountNumberChange}
                />

                {/* price  */}
                <input
                  type="text"
                  className="input input-bordered m-6 my-3"
                  placeholder="Customer details"
                  value={customer.name.length>0?`${customer.name} (${customer.number})`:""}
                  disabled
                />
                

                <button
                  disabled={ButtonSubmit}
                  className="btn text-white m-6 my-3 bg-[#213481] hover:bg-[#121f51] disabled:bg-slate-400"
                  onClick={handlePurchase}
                >
                  Purchase
                </button>
              </>
            </div>
          </div>

          
        </main>
      </div>

      

      
    </div>
  );
};

export default PayTv;
