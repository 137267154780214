import React, { useState } from "react";
import DashNavbar from "../DashNavbar";
import MTN from "../images/image 6.png";
import AIRTEL from "../images/image 7.png";
import GLO from "../images/image 8.png";
import NINEMOBILE from "../images/image 9.png";
import { useMyContext } from "../../../context/MyContext";
import { toast } from "react-toastify";
import { MdOutlineCancel } from "react-icons/md";
import axios from "axios";

const Airtime = () => {
  const [network, setNetwork] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [token] = useState(sessionStorage.getItem("authToken") || "");
  const { setIsLoading } = useMyContext();
  const [btndisable, setBtndisable] = useState(false)

  const handleNetworkChange = (e) => {
    setNetwork(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const sendAirtime = async () => {
    try {
      setBtndisable(true)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/wallet/airtime/send-airtime`,
        {
          network: network,
          number: phoneNumber,
          amount: amount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("authToken"),
          },
        }
      );

      openPasswordModal();

      if (response.data) {
        //console.log("Airtime sent successfully:", response.data);
        // toast.success("Airtime sent successfully");
        setIsPasswordModalOpen(true)
      } else {
        //console.error("Error sending airtime:", response.data);
        toast.error("Error sending airtime. Please try again.");
      }
    } catch (error) {
      //console.error("Error sending airtime:", error.message);
      toast.error(error?.response?.data?.Error?error?.response?.data?.Error:"Error requesting Airtime");
    }finally{
      setBtndisable(false)
    }
  };

  const handlePurchaseClick = async () => {
    try {
      // Send the airtime
      await sendAirtime();

      // Open the password modal after sending airtime
    } catch (error) {
      //console.error("Error sending airtime:", error.message);
      toast.error("Error sending airtime. Please try again.");
    }
  };

  const openPasswordModal = () => {
    setIsPasswordModalOpen(true);
  };

  const closePasswordModal = () => {
    setIsPasswordModalOpen(false);
    setPassword("");
  };

  const verifyPasswordAndSendData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/wallet/airtime/verify`,
        {
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("authToken"),
          },
        }
      );

      if (response.data.Access) {
        toast.success("Airtime purchased successfully");
      } else {
        toast.error("Password verification failed. Please try again.");
      }
    } catch (error) {
      //console.error("Error verifying password:", error);
      toast.error(error?.response?.data?.Error?error?.response?.data?.Error:"Error sending Airtime");
    } finally {
      setIsLoading(false);
      closePasswordModal(); // Close modal after verification attempt
    }
  };

  return (
    <div className="px-3 md:px-10">
      <DashNavbar />
      <div className="grid w-full pt-[3%] md:pt-[1%]">
        <main className="bg-[rgb(250,250,252)] rounded-2xl flex-1 overflow-y-auto p-5">
          <div>
            <div className="flex items-center justify-center text-2xl flex-col">
              <p className="text-[#213481] font-semibold">
                Airtime for all Networks
              </p>
              <p className="text-[#213481] font-bold text-4xl">Buy Airtime</p>
            </div>

            <div className="flex flex-wrap justify-center items-center lg:justify-around mt-12 md:justify-between flex-row">
              <img src={MTN} className="m-2 lg:w-auto md:w-auto w-11" alt="" />
              <img
                src={AIRTEL}
                className="m-2 lg:w-auto md:w-auto w-11"
                alt=""
              />
              <img src={GLO} className="m-2 lg:w-auto md:w-auto w-11" alt="" />
              <img
                src={NINEMOBILE}
                className="m-2 lg:w-auto md:w-auto w-11"
                alt=""
              />
            </div>

            <div className="flex flex-col p-8">
              <select
                className="select select-bordered m-6 my-3"
                value={network}
                onChange={handleNetworkChange}
              >
              
                <option value="">Network</option>
                <option value="CPLSRV001">MTN</option>
                <option value="CPLSRV023">AIRTEL</option>
                <option value="CPLSRV003">GLO</option>
                <option value="CPLSRV021">9MOBILE</option>
                <option value="CPLSRV025">SMILE</option>
              </select>
              <input
                type="number"
                className="input input-bordered m-6 my-3"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
              <input
                type="number"
                className="input input-bordered m-6 my-3"
                placeholder="Amount"
                value={amount}
                onChange={handleAmountChange}
              />

              <button
                  disabled={btndisable}

                className="btn text-white m-6 my-3 bg-[#213481] hover:bg-[#121f51]"
                onClick={handlePurchaseClick}
              >
                Purchase
              </button>
            </div>
          </div>

          {/* Password Modal */}
          {isPasswordModalOpen && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg flex flex-col p-3">
                <div className="flex flex-row justify-between my-1">
                  <p className="text-sm text-[#213481]">Confirm Transaction</p>
                  <MdOutlineCancel
                    className="text-[#7A0202] cursor-pointer"
                    onClick={closePasswordModal}
                  />
                </div>

                <p className="lg:text-2xl md:text-xl text-lg font-semibold mb-2 text-[#213481]">
                  Continue?
                </p>
                <input
                  type="password"
                  className="input input-bordered border-[#213481] mb-4"
                  placeholder="Enter your password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <button
                  className="text-white btn bg-[#213481]"
                  onClick={verifyPasswordAndSendData}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Airtime;
