import React, { useState, useEffect } from "react";
import DashNavbar from "../../DashNavbar";
import AbaEDC from "../../images/AbaElec.png";
import Aedc from "../../images/aedc.png";
import Ibedc from "../../images/ibedc.png";
import Ikedc from "../../images/Ikedc.png";
import Phedc from "../../images/phedc.png";

// import Modal from "./Modal"
import axios from "axios";
import { toast } from "react-toastify";
import { useMyContext } from "../../../../context/MyContext";
import { MdOutlineCancel } from "react-icons/md";

const Electricity = () => {
  const [network, setNetwork] = useState("");
  const [ElectricityPlan, setElectricityPlan] = useState("");
  const [PricePlan, setPricePlan] = useState(0)
  const [AccountNumber, setAccountNumber] = useState("");
  // const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [plans, setPlans] = useState([]);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [networkCode, setnetworkCode] = useState("")
  const [customer, setcustomer] = useState({name:"",address:""})
  const [PhoneNumber, setPhoneNumber] = useState("")
  const { setIsLoading } = useMyContext();
  const [resultModal, setResultModal] = useState(false)
  const [resltDetails, setResltDetails] = useState({
    unit:"",
    Token:"",
    TransactionID:"",
  })

  const [User, setUser] = useState({})
  const [specialLoad, setSpecialLoad] = useState(false)
  const [ButtonSubmit, setButtonSubmit] = useState(true)

  async function fetchData() {
    try {

      
      const response = await axios({
        url:`${process.env.REACT_APP_API_URL}/wallet/electricity/get-plans`,
        method:"get",
        headers:{ 
          authorization: sessionStorage.getItem("authToken"),
        }
      })

      const responseData = await response.data;
      console.log('====================================');
      console.log(responseData);
      console.log('====================================');
      // //console.log(responseData.Data.data);
      setPlans(responseData.Data);

      console.log('====================================');
      console.log(responseData.Data);
      console.log('====================================');

    } catch (error) {
      if (error?.response?.data)
      //console.error(error.message);
      fetchData()
    }
  }
  useEffect(() => {
    
    

    fetchData();
  }, []);

  useEffect(() => {
    // Filter plans based on selected network

    if (network) {
      if (network=="prepaid"){ 
        setnetworkCode(30)
        setFilteredPlans(plans.Prepaid);
      }
      if (network=="postpaid"){ 
        setnetworkCode(40)
        setFilteredPlans(plans.Postpaid);
      }
    
    } else {
      setFilteredPlans([]);
    }
  }, [network, plans]);

  //run api to get customer name
  useEffect(() => {
    setcustomer({name:"",address:""})
    if(network&&AccountNumber.length>10&&AccountNumber.length<14){ 
      handleResolveCustomer()
    }else{
      setButtonSubmit(true)
    }

    // setButtonSubmit(true)
  }, [AccountNumber]);

  // const handleDataTypeChange = (e) => {
  //   setDataType(e.target.value);
  // };

  const handleNetworkChange=(e)=>{
    setNetwork(e.target.value)
    setAccountNumber("")
  }

  const handleElectricityPlanChange = (e) => {
    let thepack=(filteredPlans.find(i=>i.code==e.target.value))
    setElectricityPlan(e.target.value);
    setAccountNumber("")
  };

  const handleAccountNumberChange = (e) => {
    setAccountNumber(e.target.value);
  };

  const handleAmountChange = (e) => {
   
    setPricePlan(e.target.value);
     

  };
  

  const openModal = () => {
    setIsPasswordModalOpen(true);
  };

  const closeModal = () => {
    setIsPasswordModalOpen(false);
    setPassword(""); // Clear password field on modal close
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const sendData = async () => {
    // setIsLoading(true)
    setButtonSubmit(true)
    try {

//    - `Network`: The network provider for the data package transaction (string).
// - `AccountNumber`: The recipient's meter numberr (string).
// - `Amount`: The amount to be charged for the data package (number). NOTE: This would be automatically filled depending on the data package the User Selects.
// - `Customername`: The Customer name
// - `PhoneNumber`: The customer phone number
// - `Customeraddress`: The customer addres
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/wallet/electricity/send/plan`,
        {
          Network: ElectricityPlan,
          AccountNumber: AccountNumber,
          Amount: PricePlan,
          Customername:customer.name,
          Customeraddress:customer.address,
          PhoneNumber:PhoneNumber
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: sessionStorage.getItem("authToken"),
          },
        }
      );
      setIsPasswordModalOpen(true)

      if (response.data) {

        //console.log("Airtime sent successfully:", response.data);
        toast.success("Electricity bills request successfully");

      } else {
        //console.error("Error sending Data:", response.data);
        toast.error("Error sending Electricity bills. Please try again.");
      }
    } catch (error) {
      //console.error("Error sending Data:", error.message);
      toast.error(error?.response?.data?.Error?error.response.data.Error:"Error sending Electricity bills. Please try again.");
    }finally{
      // setIsLoading(false)
      setButtonSubmit(false)

    }
  };

  const handleResolveCustomer = async () => {
    // setIsLoading(true)
    try {
      setcustomer({
        name:"loading...",
        address:"loading..."
      })

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/wallet/electricity/resolvecustomer?AccountNumber=${AccountNumber}&Network=${ElectricityPlan}`,
        
        {
          headers: {
            "Content-Type": "application/json",
            authorization: sessionStorage.getItem("authToken"),
          },
        }
      );

      // console.log(response.data);

      setcustomer({
        name:response.data.Data.name,
        address:response.data.Data.address
      })
      setButtonSubmit(false)
    } catch (error) {
      // console.log(error);
      setcustomer({
        name:"Invalid meter number",
        address:"Invalid meter number"
      })
      //console.error("Error sending Data:", error.message);
      // toast.error("Error sending Data. Please try again.");
    }finally{
      // setIsLoading(false)
    }
  };

  const handlePurchase = async () => {
    await sendData()//send data api
    // openModal(); // Open password modal
  };

  const handleCopyToken = () => {
    navigator.clipboard.writeText(resltDetails.Token);
    toast.success("Token copied to clipboard");
  };

  const handlePurchaseWithPassword = async () => {
    setPassword('')
    setIsPasswordModalOpen(false)
    setSpecialLoad(true)
    try {
      const verifyResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/wallet/electricity/verify`,
        {
          password: password,
        },
        {
          headers: {
            authorization: `${sessionStorage.getItem("authToken")}`,
          },
        }
      );

      let Data= verifyResponse.data.Data

      setResltDetails({
        unit:Data.creditToken.value,
        Token:Data.creditToken.creditToken,
        TransactionID:Data.referenceID,
      })

      setSpecialLoad(false)
      setIsPasswordModalOpen(false)

      setResultModal(true)
      toast.success("Electricity Purchased successfully");
        
      
    } catch (error) {
      //console.error("Error purchasing data:", error.request.data);
      // console.log(error);
      toast.error(error?.response?.data?.Error?error.response.data.Error:"Error sending Electricity bills. Please try again.");

      // Handle error
    }finally{
      setSpecialLoad(false)
      setIsPasswordModalOpen(false)
    }
  };

  return (
    <div className="px-3 md:px-10">
      <DashNavbar />
      {isPasswordModalOpen&&(
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg flex flex-col p-3">
                <div className="flex flex-row justify-between my-1">
                  <p className="text-sm text-[#213481]">Confirm Transaction</p>
                  <MdOutlineCancel
                    className="text-[#7A0202] cursor-pointer"
                    onClick={closeModal}
                  />
                </div>

                <p className="lg:text-2xl md:text-xl text-lg font-semibold mb-2 text-[#213481]">
                  Continue?
                </p>
                <input
                  type="password"
                  className="input input-bordered border-[#213481] mb-4"
                  placeholder="Enter your password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <button
                  className="text-white btn bg-[#213481]"
                  onClick={handlePurchaseWithPassword}
                >
                  Continue
                </button>
              </div>
            </div>
          )}


          {resultModal&&(
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg flex flex-col p-3 w-[100%] md:w-[50%] h-['100%'] md:h-auto ">
                <div className="flex flex-row justify-between my-1">
                  <p className="lg:text-2xl md:text-xl text-lg font-semibold mb-2 text-[#213481]">
                  Electricity Transaction
                  </p>
                  <MdOutlineCancel
                    className="text-[#7A0202] cursor-pointer"
                    onClick={()=>setResultModal(false)}
                  />
                </div>

                <p className="text-sm text-[#213481]">Name</p>
                <input
                  type="text"
                  className="input input-bordered border-[#213481] mb-4"
                  value={User?.User?.FullName}
                  disabled
                />

                <p className="text-sm text-[#213481]">Unit</p>
                <input
                  type="text"
                  className="input input-bordered border-[#213481] mb-4"
                  value={resltDetails.unit}
                  disabled
                />

                <p className="text-sm text-[#213481]">TransactionID</p>
                <input
                  type="text"
                  className="input input-bordered border-[#213481] mb-4"
                  placeholder="Enter your password"
                  value={resltDetails.TransactionID}
                  disabled
                />

                <p className="text-sm text-[#213481]">Token</p>
                <input
                  type="text"
                  className="input input-bordered border-[#213481] mb-4 text-lg disabled:text-[#213481] disabled:bg-white"
                  placeholder="Enter your password"
                  value={resltDetails.Token}
                  onChange={()=>{}}
                  
                  disabled
                />

                <button
                  className="text-white btn bg-[#213481]"
                  onClick={handleCopyToken}
                >
                  Copy Token
                </button>
              </div>
            </div>
          )}

          {specialLoad&&(
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg flex flex-col p-3">
                <div className="flex flex-row justify-between my-1">
                  <p className="lg:text-2xl md:text-xl text-lg font-semibold mb-2 text-[#213481]">
                    Please wait
                  </p>
                 
                </div>

              
              </div>
            </div>
          )}
      <div className="grid w-full pt-[3%] md:pt-[1%]">
        <main className="bg-[rgb(250,250,252)] rounded-2xl flex-1 overflow-y-auto p-5">
          <div>
            <div className="flex items-center justify-center text-2xl flex-col">
              <p className="text-[#213481] font-semibold">
                Pay your bills
              </p>
              <p className="text-[#213481] font-bold text-4xl">Buy Electricity Bills</p>
            </div>

            <div className="flex flex-wrap justify-center items-center lg:justify-around mt-12 md:justify-between flex-row">
              <img src={AbaEDC} className="m-2 lg:w-auto md:w-auto w-11" alt="" />
              <img
                src={Aedc}
                className="m-2 lg:w-auto md:w-auto w-11"
                alt=""
              />
              <img src={Ibedc} className="m-2 lg:w-auto md:w-auto w-11" alt="" />
              <img src={Ikedc} className="m-2 lg:w-auto md:w-auto w-11" alt="" />
              <img src={Phedc} className="m-2 lg:w-auto md:w-auto w-11" alt="" />
             
            </div>

            <div className="flex flex-col p-8">
            {/* network  */}
              <select
                className="select select-bordered m-6 my-3"
                value={network}
                onChange={handleNetworkChange}
              >
                <option value="">Electricity provider</option>
                <option value="prepaid">Prepaid</option>
                <option value="postpaid">PostPaid</option>
              </select>

              <>
               
               {/* plans  */}
                <select
                  className="select select-bordered m-6 my-3"
                  value={ElectricityPlan}
                  onChange={handleElectricityPlanChange}
                >
                  <option value="">Electricity Plan</option>
                  {filteredPlans?.map((plan) => (
                    <option key={plan.code} value={plan.code}>
                      {plan.name} {plan.description}
                    </option>
                  ))}
                </select>
                

                {/* price  */}
                <input
                  type="number"
                  className="input input-bordered m-6 mt-3 mb-0"
                  placeholder="Amount"
                  value={PricePlan}
                  onChange={handleAmountChange}
                />
                <p className="text-sm mx-6 mb-3 text-gray-600 font-normal ">EDO 100 charges are applied</p>

                <input
                  type="number"
                  className="input input-bordered m-6 my-3"
                  placeholder="Meter Number"
                  value={AccountNumber}
                  onChange={handleAccountNumberChange}
                />

                {/* price  */}
                <input
                  type="text"
                  className="input input-bordered m-6 my-3"
                  placeholder="Customer Name"
                  value={customer.name.length>0?`${customer.name}`:""}
                  disabled
                />

                <input
                  type="text"
                  className="input input-bordered m-6 my-3"
                  placeholder="Customer Address"
                  value={customer.address.length>0?`${customer.address}`:""}
                  disabled
                />
                
                <input
                  type="tel"
                  className="input input-bordered m-6 my-3"
                  placeholder="Phone Number e.g 08067890678, 09067867867"
                  max={11}
                  
                  onChange={(e)=>setPhoneNumber(e.target.value)}
                  value={PhoneNumber}
                  
                />

                <button
                  disabled={ButtonSubmit}
                  className="btn text-white m-6 my-3 bg-[#213481] hover:bg-[#121f51] disabled:bg-slate-400"
                  onClick={handlePurchase}
                >
                  Purchase
                </button>
              </>
            </div>
          </div>

          
        </main>
      </div>

      

      
    </div>
  );
};

export default Electricity;
