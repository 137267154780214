import React from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useMyContext } from "../../../context/MyContext";
import DashNavbar from "../DashNavbar";
import Slider from "react-slick";
import { settings } from "./Slider";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoEyeOutline } from "react-icons/io5";
import copy from "copy-to-clipboard";
import { ClimbingBoxLoader } from "react-spinners";
import Rating from "react-rating-stars-component";
import { FaRegClock } from "react-icons/fa6";
import { MdOutlineCancel } from "react-icons/md";

const MarketItemPage = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState({});
  const [showImage, setShowImage] = React.useState(null);
  // const isMobile = window.innerWidth <= 767;
  const { itemId } = useParams();
  const { startChat } = useMyContext();
  const [isLoading, setIsLoading] = React.useState(true);
  const [color] = React.useState("#040073");
  const [rating, setRating] = React.useState("");
  const [isWritingReview, setIsWritingReview] = React.useState(false);
  const [reviewText, setReviewText] = React.useState("");
  const textRef = React.useRef();

  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const openDropdownPopover = () => {
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const handleClick = (slide) => {
    setShowImage(slide);
  };

  const displaySlider = data.PhotoUrls
    ? data.PhotoUrls.map((slide, i) => (
        <div className="p-1 flex justify-between items-center" key={i}>
          <img
            src={slide}
            alt="image"
            className="w-48 h-36 object-cover rounded cursor-pointer"
            onClick={() => handleClick(slide)}
          />
        </div>
      ))
    : null;

  React.useEffect(() => {
    const FetchProduct = async () => {
      try {
        const userAUTH = sessionStorage.getItem("authToken");
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/marketplace/view/${itemId}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: userAUTH,
            },
          }
        );

        if (response && response.data.Access) {
          const responseData = response.data.Data || [];
          setData(responseData);
          console.log(responseData);
          setRating(responseData.Rating);
          setIsLoading(false);
          if (
            responseData.length > 0 &&
            responseData[0]?.PhotoUrls.length > 0
          ) {
            setShowImage(responseData[0].PhotoUrls[0]);
          }
        }
      } catch (error) {
        toast.error(error.response?.data.Error);
        setIsLoading(false);
      }
    };

    if (itemId) {
      FetchProduct();
    }
  }, [itemId]);

  React.useEffect(() => {
    if (data.PhotoUrls && data.PhotoUrls.length > 0 && !showImage) {
      setShowImage(data.PhotoUrls[0]);
    }
  }, [data.PhotoUrls, showImage]);

  const copyToClipboard = () => {
    let copyText = textRef.current.value;
    let isCopy = copy(copyText);
    if (isCopy) {
      toast.success("Copied to Clipboard");
    }
    closeDropdownPopover();
  };

  const handleRating = (newRating) => {
    setRating(newRating);
  };
  const handleOpenReview = () => {
    setIsWritingReview(true);
  };

  const handleCloseReview = () => {
    setIsWritingReview(false);
    setReviewText("");
    setRating(0);
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleReviewSubmit = async () => {
    setIsLoading(true);
    try {
      const userAUTH = sessionStorage.getItem("authToken");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/marketplace/view/comment/${itemId}`,
        {
          Rating: rating,
          Text: reviewText,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: userAUTH,
          },
        }
      );

      if (response && response.data.Access && response.data.Added) {
        toast.success("Review submitted successfully");
        // Optionally, you can add logic here to refresh the page or update state
      } else {
        toast.error("Failed to submit review");
      }
    } catch (error) {
      toast.error(error.response?.data.Error || "Failed to submit review");
    } finally {
      setIsLoading(false);
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          url: window.location.href,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      alert("Web Share API not supported in this browser.");
    }
  };

  return (
    <div className="w-full px-3 md:px-10">
      {isLoading && (
        <div className="w-full h-screen flex justify-center items-center">
          <ClimbingBoxLoader
            color={color}
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="flex justify-center items-center"
          />
        </div>
      )}
      {!isLoading && (
        <>
          <DashNavbar />
          <div className="grid w-full pt-[3%] md:pt-[1%] my-10 lg:w-[85%] py-3 px-2 md:px-5 bg-[#FAFAFc] rounded-md relative overflow-hidden">
            <div className="flex items-center md:w-[40%] lg:w-[37%]">
              <Link
                className="text-[15px] capitalize md:text-base text-black hover:text-blue-900"
                to="/market"
              >
                MarketPlace
              </Link>
              <p className="text-[15px] md:text-lg text-black mx-2">/</p>
              <span className="text-[15px] capitalize md:text-base text-blue-700">
                {data.Title}
              </span>
            </div>

            <div className="grid md:grid-cols-3 gap-8 py-3 mt-3 md:py-7">
              <div className="md:col-span-2 w-full md:w-full">
                <div className="w-full">
                  {showImage && (
                    <img
                      id="show image"
                      src={showImage}
                      alt="image"
                      className="pb-5 w-[400px] md:h-[510px] md:w-full object-cover rounded-md"
                    />
                  )}
                </div>

                <div className="p-1 flex items-center lg:justify-start md:justify-start sm:justify-start justify-center lg:flex-row  md:flex-row lg:flex-nowrap md:flex-nowrap flex-wrap">
                  {data.PhotoUrls.map((slide, i) => (
                    <img
                      src={slide}
                      alt="image"
                      key={i}
                      className=" w-28 h-24 object-cover rounded cursor-pointer m-1"
                      onClick={() => handleClick(slide)}
                    />
                  ))}
                </div>

                <div className="w-full p-2 mt-3 ">
                  <div className="w-full md:flex justify-between items-center">
                    <div className="md:w-[70%]">
                      <h3 className="text-black text-lg font-medium">
                        {data.Title}
                      </h3>
                      <div className="w-full flex items-center text-[13px] font-thin text-[#AEAEAE]">
                        <span>
                          Posted{" "}
                          {(() => {
                            const transferData = new Date(data.createdAt);
                            const formattedTransferData =
                              transferData.toLocaleString();
                            return formattedTransferData;
                          })()}
                        </span>

                        <p className="text-base mx-2">
                          <IoEyeOutline />
                        </p>
                        <span>
                          {data.LGA}, {data.State}
                        </span>
                      </div>
                      <h3 className="text-black text-2xl font-semibold py-2">
                        {data.Price?.$numberDecimal
                          ? `EDO ${data.Price.$numberDecimal}`
                          : ""}
                      </h3>
                    </div>
                    <div className="w-[50%] md:w-[20%]">
                      <div class="flex items-center justify-around pt-3 pb-2">
                        <div>
                          <Rating
                            count={5}
                            size={24}
                            value={rating}
                            // onChange={handleRating}
                            activeColor="#ffd700"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-black relative text-[15px] mt-2 font-light">
                    <p className="">{data.Description}</p>{" "}
                  </div>
                  <div className="flex justify-center items-center">
                    <p className="text-[#405AC6] text-lg font-semibold p-5">
                      <button
                        onClick={handleOpenReview}
                        className=" hover:underline"
                      >
                        Write a Review
                      </button>
                    </p>
                  </div>
                  <div className="text-black">
                    <p>Reviews ({data.TotalReview})</p>
                    <hr className="h-px my-4 bg-gray-400 border-0" />
                    {data.AllReview.map((review) => (
                      <div
                        key={review._id}
                        className="flex flex-row items-center mb-4"
                      >
                        <img
                          src={review.ProfilePhotoURL}
                          alt="Profile"
                          className="w-10 h-10 rounded-full mr-3"
                        />
                        <div>
                          <div className="flex items-center">
                            <p className="text-base font-semibold">
                              {review.Username}
                            </p>
                            <div className="flex items-center text-gray-600 ml-2">
                              <FaRegClock className="mx-1 text-xs" />
                              <p className="text-xs">
                                {new Date(review.createdAt).toLocaleString()}
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <Rating
                              count={5}
                              size={20}
                              value={review.Rating}
                              activeColor="#ffd700"
                              edit={false} // Disable editing for display-only
                            />
                          </div>
                          <p className="text-sm">{review.Text}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {isWritingReview && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="bg-white p-6 rounded-lg max-w-md text-black flex flex-col items-center">
                    <div className="flex flex-row justify-between my-1">
                      <p className="text-sm text-[#fff]">Confirm Transaction</p>
                      <MdOutlineCancel
                        className="text-[#7A0202] cursor-pointer"
                        onClick={handleCloseReview}
                      />
                    </div>
                    <h2 className="text-xl font-semibold mb-3">
                      Write a Review
                    </h2>
                    <textarea
                      value={reviewText}
                      onChange={(e) => setReviewText(e.target.value)}
                      placeholder="Write your review here..."
                      className="w-full p-2 border border-gray-300 rounded mb-3 textarea textarea-info textarea-lg"
                    />
                    <div className="mb-3 flex flex-col items-center">
                      <p>Rate the product:</p>
                      <div>
                        {[1, 2, 3, 4, 5].map((star) => (
                          <span
                            key={star}
                            onClick={() => handleRatingChange(star)}
                            className={`text-2xl cursor-pointer ${
                              star <= rating
                                ? "text-yellow-400"
                                : "text-gray-300"
                            }`}
                          >
                            ★
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <button
                        onClick={handleReviewSubmit}
                        className="bg-blue-900 text-white px-4 py-2 rounded hover:bg-blue-800"
                      >
                        Send Review
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div className="md:col-span-1 w-full">
                <div className="bg-white rounded drop-shadow-sm p-5 md:w-full">
                  <div className="flex items-center">
                    <img
                      src={data?.PhotoUrl}
                      alt="image"
                      className="w-12 h-12 md:w-20 md:h-20 object-cover rounded-full"
                    />
                    <div className="ml-3">
                      <h3 className="text-black capitalize text-xl font-medium">
                        {data.FullName}
                      </h3>
                      <span className="flex items-center font-thin text-[#AEAEAE] py-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          class="bi bi-envelope-at"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                          <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648m-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                        </svg>
                        <p className="ml-2 text-[13px]">
                          Replies within 30 minutes
                        </p>
                      </span>
                    </div>
                  </div>
                  <div className="pt-6 flex flex-col items-center justify-center">
                    <Link
                      to={"/chat"}
                      onClick={() => startChat(data.SellID)}
                      className="w-full flex justify-center itemsz-center p-3 text-base rounded-lg text-center text-white font-normal uppercase bg-blue-900 hover:bg-blue-800 my-3"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="currentColor"
                        class="bi bi-envelope-at"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                        <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648m-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                      </svg>
                      <span className="ml-4 text-sm mdtext-base">
                        start chat
                      </span>
                    </Link>
                    <button
                      onClick={() => {
                        dropdownPopoverShow
                          ? closeDropdownPopover()
                          : openDropdownPopover();
                      }}
                      className="w-full flex justify-center items-center p-3 rounded-lg text-center text-blue-900 font-normal uppercase border border-blue-900"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="currentColor"
                        class="bi bi-telephone"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                      </svg>
                      <span className="ml-3 text-sm md:text-base">
                        Show Contact
                      </span>
                    </button>
                    {/* <br /> */}
                    <button
                      onClick={handleShare}
                      className="my-3 w-full flex justify-center items-center p-3 rounded-lg text-center text-blue-900 font-normal uppercase border border-blue-900"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="currentColor"
                        class="bi bi-share"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3" />
                      </svg>
                      <span className="ml-3 text-sm md:text-base">
                        share product
                      </span>
                    </button>
                    <div className="flex flex-wrap relative bottom-10">
                      <div className="">
                        <div
                          className={
                            (dropdownPopoverShow ? "block " : "hidden ") +
                            "text-base z-50 float-left py-2 list-none text-left rounded shadow-md mt-1 w-full bg-[#FAFAFc]"
                          }
                        >
                          <div className="">
                            <div className="flex justify-between items-center py-2 px-2 border-b-2 border-gray-400">
                              {/* <span className="text-base font-normal text-black ">
                            +{data.PhoneNumber}
                          </span> */}
                              <input
                                value={data.PhoneNumber}
                                disabled
                                type="text"
                                ref={textRef}
                              />
                              <button
                                className="flex items-center text-base py-2 px-7 rounded-md text-center text-white font-normal uppercase bg-blue-900 hover:bg-blue-800 my-3"
                                type="button"
                                onClick={copyToClipboard}
                              >
                                Copy
                              </button>
                            </div>
                            <div className="mt-3 p-2">
                              <div className="flex pt-1">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="currentColor"
                                    class="bi bi-exclamation-lg"
                                    viewBox="0 0 16 16"
                                    className="text-red-800"
                                  >
                                    <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0zM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0" />
                                  </svg>
                                </span>
                                <span className="text-sm ml-1 text-black text-[17px]">
                                  Never pay in advance! Even for the delivery
                                </span>
                              </div>
                              <div className="flex pt-2">
                                <span className="">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-check-square-fill"
                                    viewBox="0 0 16 16"
                                    className="text-lime-500"
                                  >
                                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                  </svg>
                                </span>
                                <span className="text-sm ml-2 text-black">
                                  Inform the seller you got their number on
                                  Buzzy so they know where you came from
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white text-black rounded drop-shadow-sm p-5 mt-4">
                  <h3 className="text-blue-900 font-medium text-start text-xl">
                    Safety Tips
                  </h3>
                  <div className="mt-3">
                    <div className="flex pt-1">
                      <span>1.</span>{" "}
                      <span className="text-sm ml-2">
                        Refrain from making upfront payments, especially before
                        receiving the product.
                      </span>
                    </div>
                    <div className="flex pt-1">
                      <span>2.</span>{" "}
                      <span className="text-sm ml-2">
                        Arrange to meet the seller in a secure public location
                      </span>
                    </div>
                    <div className="flex pt-1">
                      <span>3.</span>{" "}
                      <span className="text-sm ml-2">
                        Thoroughly examine the item to confirm it meets your
                        specifications.
                      </span>
                    </div>
                    <div className="flex pt-1">
                      <span>4.</span>{" "}
                      <span className="text-sm ml-2">
                        Verify that the packaged item matches the one you
                        inspected.
                      </span>
                    </div>
                    <div className="flex pt-1">
                      <span>5.</span>{" "}
                      <span className="text-sm ml-2">
                        Complete the payment only when you are content with the
                        purchase.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MarketItemPage;
