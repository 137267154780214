import React, { useState, useEffect } from "react";
import DashNavbar from "../DashNavbar";
import MTN from "../images/image 6.png";
import AIRTEL from "../images/image 7.png";
import GLO from "../images/image 8.png";
import NINEMOBILE from "../images/image 9.png";
// import Modal from "./Modal";
import axios from "axios";
import { toast } from "react-toastify";
import { useMyContext } from "../../../context/MyContext";
import { MdOutlineCancel } from "react-icons/md";

const DataPurchase = () => {
  const [network, setNetwork] = useState("");
  const [dataType, setDataType] = useState("");
  const [dataPlan, setDataPlan] = useState("");
  const [PricePlan, setPricePlan] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [plans, setPlans] = useState([]);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [networkCode, setnetworkCode] = useState("");
  const { setIsLoading } = useMyContext();
  const [btndisable, setBtndisable] = useState(false)

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/wallet/data/get-plans`,
          {
            method: "GET",
            headers: {
              Authorization: sessionStorage.getItem("authToken"),
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data plans");
        }

        const responseData = await response.json();
        // //console.log(responseData.Data.data);
        setPlans(responseData.Data);
      } catch (error) {
        //console.error(error.message);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    // Filter plans based on selected network

    // {
    //   mtn:"CPLSRV002",
    //   glo:"CPLSRV004",
    //   _9mobile:"CPLSRV022",
    //   "airtel":"CPLSRV024",
    //   SMILE:"CPLSRV026",
    //   SPECTRANET:"CPLSRV027"
    // }

    if (network) {
      if (network == "mtn") {
        setnetworkCode("CPLSRV002");
        setFilteredPlans(plans.mtnData);
      }
      if (network == "airtel") {
        setnetworkCode("CPLSRV024");
        setFilteredPlans(plans.airtelData);
      }
      if (network == "glo") {
        setnetworkCode("CPLSRV004");
        setFilteredPlans(plans.gloData);
      }
      if (network == "9mobile") {
        setnetworkCode("CPLSRV022");
        setFilteredPlans(plans._9mobileData);
      }
    } else {
      setFilteredPlans([]);
    }
  }, [network, plans]);

  const handleNetworkChange = (e) => {
    setNetwork(e.target.value);
    setDataType(""); // Reset data type and plan when network changes
    setDataPlan("");
  };

  // const handleDataTypeChange = (e) => {
  //   setDataType(e.target.value);
  // };

  const handleDataPlanChange = (e) => {
    let val= JSON.parse(e.target.value)
    setDataPlan(val?.code);
    setPricePlan(
      val?.Amount
    );
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const openModal = () => {
    setIsPasswordModalOpen(true);
  };

  const closeModal = () => {
    setIsPasswordModalOpen(false);
    setPassword(""); // Clear password field on modal close
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const sendData = async () => {
    // setIsLoading(true);
    try {
      setBtndisable(true)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/wallet/data/sendData`,
        {
          network: networkCode,
          number: phoneNumber,
          amount: PricePlan,
          package: dataPlan,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("authToken"),
          },
        }
      );
      setIsPasswordModalOpen(true);

      if (response.data) {
        //console.log("Airtime sent successfully:", response.data);
        // toast.success("Data request successfully");
      } else {
        //console.error("Error sending Data:", response.data);
        toast.error("Error sending Data. Please try again.");
      }
    } catch (error) {
      console.error("Error sending Data:", error.message);
      toast.error(error?.response?.data?.Error?error?.response?.data?.Error:"Error sending data");
    } finally {
      setBtndisable(false);
    }
  };

  const handlePurchase = async () => {
    await sendData(); //send data api
    openModal(); // Open password modal
  };

  const handlePurchaseWithPassword = async () => {
    setIsLoading(true);
    try {
      const verifyResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/wallet/data/verify`,
        {
          password: password,
        },
        {
          headers: {
            Authorization: `${sessionStorage.getItem("authToken")}`,
          },
        }
      );

      if (verifyResponse.data.Access) {
        toast.success("Data sent successfully");
      } else {
        //console.error("Password verification failed");
        toast.error("Password verification failed");
        // Handle password verification failure
      }
    } catch (error) {
      //console.error("Error purchasing data:", error.request.data);
      //console.log(error);
      toast.error(error?.response?.data?.Error?error?.response?.data?.Error:"Error sending data");
      // Handle error
    } finally {
      setIsLoading(false);
      // setIsModalOpen(false);
    }
  };

  return (
    <div className="px-3 md:px-10">
      <DashNavbar />
      {isPasswordModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg flex flex-col p-3">
            <div className="flex flex-row justify-between my-1">
              <p className="text-sm text-[#213481]">Confirm Transaction</p>
              <MdOutlineCancel
                className="text-[#7A0202] cursor-pointer"
                onClick={closeModal}
              />
            </div>

            <p className="lg:text-2xl md:text-xl text-lg font-semibold mb-2 text-[#213481]">
              Continue?
            </p>
            <input
              type="password"
              className="input input-bordered border-[#213481] mb-4"
              placeholder="Enter your password"
              value={password}
              onChange={handlePasswordChange}
            />
            <button
              className="text-white btn bg-[#213481]"
              onClick={handlePurchaseWithPassword}
            >
              Continue
            </button>
          </div>
        </div>
      )}
      <div className="grid w-full pt-[3%] md:pt-[1%]">
        <main className="bg-[rgb(250,250,252)] rounded-2xl flex-1 overflow-y-auto p-5">
          <div>
            <div className="flex items-center justify-center text-2xl flex-col">
              <p className="text-[#213481] font-semibold">
                Data for all Networks
              </p>
              <p className="text-[#213481] font-bold text-4xl">Buy Data</p>
            </div>

            <div className="flex flex-wrap justify-center items-center lg:justify-around mt-12 md:justify-between flex-row">
              <img src={MTN} className="m-2 lg:w-auto md:w-auto w-11" alt="" />
              <img
                src={AIRTEL}
                className="m-2 lg:w-auto md:w-auto w-11"
                alt=""
              />
              <img src={GLO} className="m-2 lg:w-auto md:w-auto w-11" alt="" />
              <img
                src={NINEMOBILE}
                className="m-2 lg:w-auto md:w-auto w-11"
                alt=""
              />
            </div>

            <div className="flex flex-col p-8">
              <select
                className="select select-bordered m-6 my-3"
                value={network}
                onChange={handleNetworkChange}
              >
                <option value="">Network</option>
                <option value="mtn">MTN</option>
                <option value="airtel">AIRTEL</option>
                <option value="glo">GLO</option>
                <option value="9mobile">9MOBILE</option>
              </select>

              <>
                {/* <select
                  className="select select-bordered m-6 my-3"
                  value={dataType}
                  onChange={handleDataTypeChange}
                >
                  <option value="">Data Type</option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </select> */}

                <select
                  className="select select-bordered m-6 my-3"
                  // value={dataPlan}
                  onChange={handleDataPlanChange}
                >
                  <option value="">Data Plan</option>
                  {filteredPlans?.map((plan,id) => (
                    <option key={id} value={JSON.stringify(plan)}>
                      {plan.short_description}
                    </option>
                  ))}
                </select>

                <input
                  type="number"
                  className="input input-bordered m-6 my-3"
                  placeholder="Phone Number"
                  value={PricePlan}
                  disabled
                />

                <input
                  type="number"
                  className="input input-bordered m-6 my-3"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                />

                <button 
                  disabled={btndisable}
                  className="btn text-white m-6 my-3 bg-[#213481] hover:bg-[#121f51]"
                  onClick={handlePurchase}
                >
                  Purchase
                </button>
              </>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default DataPurchase;
