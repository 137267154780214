export var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  arrows: false,
  autoPlay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};




{/* <div className="w-[50%] md:w-[20%]">
                      <div>
                        <Rating
                          count={5}
                          size={24}
                          value={rating}
                          onChange={handleRating}
                          activeColor="#ffd700"
                        />
                      </div>
                    </div>  */}


// const handleRating = (newRating) => {
//   setRating(newRating);
// };

// const [rating, setRating] = React.useState(0);

// import Rating from "react-rating-stars-component";