import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DashNavbar from "../DashNavbar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "./Sub-Wallet/Modal";
import { ClimbingBoxLoader } from "react-spinners";

const DashMyProdut = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const recordsPerPage = 20;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = data.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(data.length / recordsPerPage);
  const numbers = [...Array(nPage + 1).keys()].slice(1);
  const [openPromote, setOpenPromote] = React.useState(false);
  const [promotePrice, setPromotePrice] = React.useState("");
  const [promoteSellId, setPromoteSellId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [color] = React.useState("#040073");

  React.useEffect(() => {
    FetchProduct();
  }, []);

  const FetchProduct = async () => {
    const userAUTH = sessionStorage.getItem("authToken");
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/marketplace/view/myproducts`,
        {
          headers: {
            Accept: "application/json",
            Authorization: userAUTH,
          },
        }
      );

      if (response) {
        const responseData = response.data;
        if (responseData.Access === true) {
          const products = responseData.Data || [];
          setData(products);
          setIsLoading(false);
          // console.log("Product:", products);
          // console.log("Pro:", responseData);
          setPromotePrice(responseData.Promoteprice);
        }
      }
    } catch (error) {
      setIsLoading(false);
      // console.error("Error fetching marketplace data:", error);
      // console.error("Unexpected error:", error.response?.data || error.message);
    }
  };

  const Refresh = () => {
    FetchProduct();
  };

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPage = () => {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const changeCPage = (id) => {
    setCurrentPage(id);
  };

  const HandleDelete = async (itemId) => {
    const userAUTH = sessionStorage.getItem("authToken");
    setOpenPromote(false);
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/marketplace/view/delete/myproduct/${itemId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: userAUTH,
          },
        }
      );

      if (response) {
        setIsLoading(false);
        toast.success("Deleted successful");
        FetchProduct();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response?.data.Error || "Unknown error");
      // console.error("Error fetching marketplace data:", error);
      // console.error("Unexpected error:", error.response?.data || error.message);
    }
  };

  const HandlePromotePackage = async () => {
    const userAUTH = sessionStorage.getItem("authToken");
    setOpenPromote(false);
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/marketplace/view/promote/myproduct/${promoteSellId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: userAUTH,
          },
        }
      );

      if (response && response.data.Access === true) {
        setIsLoading(false);
        toast.success("Promoted successful");
        FetchProduct();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response?.data.Error || "An unexpected error occurred");
      // console.error("Unexpected error:", error.response?.data || error.message);
    }
  };

  const handlePromoteProduct = (sellId) => {
    setPromoteSellId(sellId);
    setOpenPromote(true);
  };

  return (
    <div className="w-full px-3 md:px-10">
      {isLoading && (
        <div className="w-full h-screen flex justify-center items-center">
          <ClimbingBoxLoader
            color={color}
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="flex justify-center items-center"
          />
        </div>
      )}
      {!isLoading && (
        <>
          <DashNavbar />
          <div className="grid w-full pt-[3%] md:pt-[1%] my-10">
            <div className="md:w-[85%]">
              <div className="w-full md:flex flex-row">
                <div className="md:w-[15%] h-[100%] pl-8"></div>
                {records && records.length > 0 ? (
                  <div className="md:w-[85%] p-3 bg-[#FAFAFc] rounded-md">
                    <div className="w-full grid md:grid-cols-3 gap-2">
                      <div className="py-2 md:col-span-2">
                        {/* <div class="flex w-[70%] rounded border bg-white">
                            <input
                              class=" w-full bg-transparent px-4 py-1 text-gray-400 outline-none focus:outline-none "
                              type="search"
                              name="search"
                              placeholder="Search for anything..."
                            />
                            <button
                              type="submit"
                              class="m-2 rounded px-4 py-1 text-white"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="20"
                                height="20"
                                viewBox="0 0 50 50"
                              >
                                <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"></path>
                              </svg>
                            </button>
                          </div> */}
                      </div>
                    </div>
                    <div className="w-full grid md:grid-cols-3 lg:grid-cols-4 gap-3 md:mt-5">
                      {records.map((item) => (
                        <div
                          className="w-full rounded border hover:shadow-lg bg-white text-center py-1 object-cover scale-70"
                          key={item.SellID}
                        >
                          {/* Content for each item */}
                          <div className="relative group px-[10px] my-5">
                            <div className="flex h-40 group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-black/30">
                              {/* Image */}
                              <img
                                src={
                                  item.PhotoUrls && item.PhotoUrls.length > 0
                                    ? item.PhotoUrls[0]
                                    : null
                                }
                                alt={item.alt}
                                className="w-full object-contain transition-transform duration-500"
                              />
                            </div>

                            <div className="mt-4 px-1">
                              {/* <div className="flex items-center py-2">
                                  {[...Array(5)].map((_, index) => (
                                    <img
                                      key={index}
                                      className="w-3 h-3"
                                      width="24"
                                      height="24"
                                      src="https://img.icons8.com/material-sharp/24/213481/star--v1.png"
                                      alt="star--v1"
                                    />
                                  ))}
                                  <p className="text-[12px] px-[4px] text-start font-thin text-gray-400">
                                    (256)
                                  </p>
                                </div> */}
                              {/* Title */}
                              <h3 className='text-black text-start text-[15px] font-mono"'>
                                {item.Title}
                              </h3>
                              {/* More details */}
                              {/* <div className="flex items-center text-blue-900 text-[12px] mt-1">
                                <span>More Details</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  fill="currentColor"
                                  class="bi bi-plus-circle-dotted"
                                  viewBox="0 0 16 16"
                                  className="ml-2"
                                >
                                  <path d="..." />
                                </svg>
                              </div> */}
                              {/* Price */}
                              <p className="text-[17px] text-start pt-2 text-black font-medium">
                                {item.Price?.$numberDecimal
                                  ? `EDO ${item.Price.$numberDecimal}`
                                  : "N/A"}
                              </p>
                            </div>
                            {/* Delete button */}
                            <button
                              type="button"
                              onClick={() => HandleDelete(item.SellID)}
                              className="border border-blue-900 text-blue-900 uppercase text-[15px] w-full p-2 rounded-md md:mt-3"
                            >
                              DELETE PRODUCT
                            </button>
                            <button
                              type="button"
                              onClick={() => handlePromoteProduct(item.SellID)}
                              className="border border-blue-900 text-blue-900 uppercase text-[15px] w-full p-2 rounded-md md:mt-3"
                            >
                              PROMOTE PRODUCT
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="w-full h-[40vh] md:h-[55vh] flex justify-center items-center">
                    <div className="md:w-[50%]">
                      <h3 className="text-center font-normal text-lg text-black">
                        Opps no product found
                      </h3>
                      <p className="text-center font-normal text-sm text-gray-600">
                        You dont have any product yet. you can go back and add
                        your product.
                      </p>
                    </div>
                  </div>
                )}
              </div>

              {data.length > 16 ? (
                <div class="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                  <nav className="w-full  my-5 md:my-10">
                    <ul class="flex w-[30%] mx-auto">
                      <li>
                        <a
                          class="mx-1 flex h-9 w-9 items-center justify-center rounded-full border-blue-gray-100 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out border text-blue-900 hover:border-blue-900 hover:text-blue-white"
                          href="#"
                          aria-label="Previous"
                          onClick={prePage}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-arrow-left"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                            />
                          </svg>
                        </a>
                      </li>

                      {numbers.map((n, i) => (
                        <li className="group" key={i}>
                          <a
                            class={`mx-1 flex h-9 w-9 items-center justify-center rounded-full bg-white p-0 text-base border text-blue-900 transition duration-150 ease-in-out hover:border-none hover:text-white hover:bg-blue-900 ${
                              currentPage === n ? "bg-blue-900" : ""
                            }`}
                            href="#"
                            onClick={changeCPage}
                          >
                            {n}
                          </a>
                        </li>
                      ))}

                      <li>
                        <a
                          class="mx-1 flex h-9 w-9 items-center justify-center rounded-full border-blue-gray-100 bg-transparent p-0 text-sm text-blue-gray-500 transition duration-150 ease-in-out border text-blue-900 hover:border-blue-900 hover:text-blue-white"
                          href="#"
                          aria-label="Next"
                          onClick={nextPage}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-arrow-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                            />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}

      <Modal open={openPromote} onClose={() => setOpenPromote(false)}>
        <div className="py-3">
          <p className="text-black text-base">
            Do you want to promote your product for EDO
            {promotePrice}
          </p>
          <div className="flex justify-between items-center">
            <button
              type="submit"
              onClick={() => setOpenPromote(false)}
              class="w-[40%] mt-5 text-blue-900 border border-blue-900 focus:ring-4 font-medium rounded-md text-sm px-2 md:px-5 py-2.5 text-center"
            >
              Cancel
            </button>
            <button
              type="submit"
              // onClick={handleScreenshot}
              onClick={HandlePromotePackage}
              class="w-[40%] mt-5 text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 font-medium rounded-md text-sm px-5 py-2.5 text-center"
            >
              Promote
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DashMyProdut;
