import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMyContext } from "../context/MyContext";

const SignUpForm1 = ({ code }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [number, setNumber] = useState(0);
  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [referral, setReferral] = useState(code);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [authToken, setAuthToken] = useState(""); // Make sure to set the actual token
  const { email, isLoading, setEmail, setIsLoading } = useMyContext();

  const navigate = useNavigate();

  // Load state from local storage on component mount
  useEffect(() => {
    const savedState =
      JSON.parse(localStorage.getItem("signupForm1State")) || {};
    setFullName(savedState.fullName || "");
    setUsername(savedState.username || "");
    setEmail(savedState.email || "");
    setPassword(savedState.password || "");
    setReferral(savedState.referral || code);
    setAgreeTerms(savedState.agreeTerms || false);
  }, []);

  // Save state to local storage whenever it changes
  useEffect(() => {
    const stateToSave = {
      fullName,
      email,
    };
    localStorage.setItem("signupForm1State", JSON.stringify(stateToSave));
  }, [fullName, email]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSendOtp = async (e) => {
    // e.preventDefault();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/register/otp?email=${email}`
      );

      const data = response.data;

      if (data.Access) {
        if (data.Sent) {
          toast.success("OTP sent successfully");
          // navigate("register2");
        } else {
          toast.error("Sending OTP failed: OTP not sent");
        }
      } else {
        toast.error(data.Error || "Unknown error");
      }
    } catch (error) {
      toast.error(error.response?.data?.Error || "Unknown error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/auth/register/1`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken, // Make sure authToken is defined
        },
        data: JSON.stringify({
          FullName: fullName,
          Username: username,
          PhoneNumber: number,
          Email: email,
          Password: password,
          Referral: referral,
        }),
      });

      const data = response.data;

      if (data.Access) {
        if (data.Registered) {
          handleSendOtp();
          navigate(`/register2/${email}`);
        } else {
          toast.error("Registration failed: User not registered");
        }
      } else {
        toast.error(data.Error || "Unknown error");
      }
    } catch (error) {
      toast.error(error.response?.data?.Error || "Unknown error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-sm w-full">
      <h2 className="text-3xl font-extrabold text-black">
        Get Started on BUZZY
      </h2>
      <p className="mt-2 text-sm text-gray-600">
        Create a new account with us and get started
      </p>

      <form className="mt-4" onSubmit={handleSubmit}>
        <div>
          <label
            htmlFor="fullname"
            className="block text-sm font-medium text-black"
          >
            Full Name
          </label>
          <input
            type="text"
            id="name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            className="mt-1 p-2 w-full input input-primary border rounded-md focus:outline-none focus:border-blue-500"
            placeholder="Enter your full name"
          />
        </div>

        <div className="mt-2">
          <label
            htmlFor="username"
            className="block text-sm font-medium text-black"
          >
            Username
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 input input-primary"
            placeholder="Enter your username"
            required
          />
          <p className="mt-2 mb-4 text-sm text-gray-600">
            Your username is also your handle. Make sure you create something
            unique and interesting
          </p>
        </div>

        <div className="mt-2">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-black"
          >
            Phone Number
          </label>
          <input
            type="number"
            id="number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            className="mt-1 p-2 w-full input input-primary border rounded-md focus:outline-none focus:border-blue-500"
            placeholder="Your Phone Number"
          />
        </div>

        <div className="mt-2">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-black"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 p-2 w-full input input-primary border rounded-md focus:outline-none focus:border-blue-500"
            placeholder="Your email"
          />
        </div>

        <div className="mt-2">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-black"
          >
            Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              minLength={6}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 p-2 w-full input input-primary border rounded-md focus:outline-none focus:border-blue-500"
              placeholder="Your password"
            />
            <button
              type="button"
              className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <p className="mt-2 mb-4 text-xs text-gray-600">
            Password must contain at least 6 characters, 1 symbol, 1 capital
            letter, and 1 small letter
          </p>
        </div>

        <div className="mt-2">
          <label
            htmlFor="referral"
            className="block text-sm font-medium text-black"
          >
            Referrer’s username (optional)
          </label>
          <input
            type="text"
            id="referral"
            value={referral}
            onChange={(e) => setReferral(e.target.value)}
            className="mt-1 p-2 w-full input input-primary border rounded-md focus:outline-none focus:border-blue-500"
            placeholder="Enter your referral code"
          />
        </div>

        <div className="my-2 flex">
          <p className="text-base text-left">
            <input
              type="checkbox"
              checked={agreeTerms}
              onChange={() => setAgreeTerms(!agreeTerms)}
              className="mr-2"
            />
            By signing up, you agree to our{" "}
            <Link to="#">
              <span className="login-text">Terms and Privacy Policy</span>
            </Link>
          </p>
        </div>

        <div className="mt-6">
          <button
            type="submit"
            disabled={!agreeTerms}
            className="w-full py-2 px-4 border rounded-md text-white hover:bg-blue-800 focus:outline-none focus:border-blue-700"
          >
            Create an account
          </button>
        </div>

        {/* <p className="mt-2 text-red-600 text-center text-sm">{errorText}</p> */}

        <p className="text-base my-3 text-center items-center">
          Already have an account on Buzzy?{" "}
          <Link to="/login">
            <span className="login-text">Login Now</span>
          </Link>
        </p>
      </form>
    </div>
  );
};

export default SignUpForm1;
