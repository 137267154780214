import React, { useState, useEffect } from "react";
import facebook from "../../../images/Facebook.png";
import whatsapp from "../../../images/WhatsApp.svg";
import tiktok from "../../../images/TikTok.png";
import instagram from "../../../images/instagram.svg";
import twitter from "../../../images/x.svg";
import linkedin from "../../../images/LinkedIn.svg";
import { useMyContext } from "../../../context/MyContext";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import DashNavbar from "../DashNavbar";
import { ClimbingBoxLoader } from "react-spinners";

const TaskDelivery = () => {
    const navigate = useNavigate();
    const { itemId } = useParams();

    const [accordionOpen, setAccordionOpen] = useState(null);
    const [activePlatform, setActivePlatform] = useState(null);
    const [advertTasks, setAdverttasks] = useState();
    const [singleAdvert, setSingleAdvert] = useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const [color] = React.useState("#040073");
    const singleAdvertCache = React.useMemo(() => new Map(), []);

    const toggleAccordion = async (platform, _id) => {
        setAccordionOpen((prev) => (prev === _id ? null : _id));
        setActivePlatform(platform);

        // Check if the data is already in the cache
        if (singleAdvertCache.has(_id)) {
            setSingleAdvert(singleAdvertCache.get(_id));
        } else {
            // If not in the cache, fetch from the API
            await fetchSingleAdvert(_id);
        }
    };

    const platformNames = {
        Whatsapp: {
            image: whatsapp,
            text: "Get People to Post Your Adverts on their WhatsApp status",
        },
        Facebook: {
            image: facebook,
            text: "Get People to Post Your Adverts on Facebook",
        },
        Instagram: {
            image: instagram,
            text: "Get People to Post Your Adverts on Instagram",
        },
        X: {
            image: twitter,
            text: "Get People to Post Your Adverts on Twitter",
        },
        TikTok: {
            image: tiktok,
            text: "Get People to Post Your Adverts on TikTok",
        },
        LinkedIn: {
            image: linkedin,
            text: "Get People to Post Your Adverts on LinkedIn",
        },
        // Replace the following with actual MongoDB ObjectIds and platform names
        // "462l6c7a7bf30784523f130f": { image: yourImage, text: "Your platform description" },
        // Add other platforms as needed
    };

    const fetchSingleAdvert = async (_id) => {
        setIsLoading(true);
        try {
            const authToken = sessionStorage.getItem("authToken");

            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/seller/advert/view/1/${itemId}`,
                {
                    headers: {
                        Authorization: authToken,
                    },
                }
            );

            setSingleAdvert(response.data.Data);

            console.log("All Single Task", response.data.Data);
            setIsLoading(false);
        } catch (error) {
        } finally {
        }
    };

    const fetchAdvData = async () => {
        try {
            const authToken = sessionStorage.getItem("authToken");

            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/seller/advert/view/all`,
                {
                    headers: {
                        Authorization: authToken,
                    },
                }
            );

            // Save user data to both session and userDataE
            sessionStorage.setItem("tasksAdv", JSON.stringify(response.data.Data));
            setAdverttasks(response.data.Data);

            setAdverttasks(response.data.Data);
        } catch (error) {
        } finally {
        }
    };

    useEffect(() => {
        fetchSingleAdvert();
    }, []);

    const handleApproveTask = async (advertID, performTaskID) => {
        setIsLoading(true);
        try {
            const authToken = sessionStorage.getItem("authToken");

            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/seller/advert/action/approve/${advertID}?PerformTaskID=${performTaskID}`,
                {
                    headers: {
                        Authorization: authToken,
                    },
                }
            );

            if (response.data.Access && !response.data.Error) {
                toast.success("Task approved successfully");
            } else {
                toast.error("Error approving task");
            }

            // Handle the response as needed
            if (response.data.Access && !response.data.Error) {
                // Task approved successfully, you can update state or show a message
            } else {
                // Handle errors or display a message
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeclineTask = async (advertID, performTaskID) => {
        setIsLoading(true);
        try {
            const authToken = sessionStorage.getItem("authToken");

            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/seller/advert/action/decline/${advertID}?PerformTaskID=${performTaskID}`,
                {
                    headers: {
                        Authorization: authToken,
                    },
                }
            );

            // Handle the response as needed
            if (response.data.Access && !response.data.Error) {
                toast.success("Task declined successfully");
            } else {
                toast.error("Error declining task");
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const TT = sessionStorage.getItem("tasksAdv");
    const Tasks = TT ? JSON.parse(TT) : [];

    const formatDate = (dateTimeString) => {
        const date = new Date(dateTimeString);
        return date.toLocaleString(); // Adjust the format based on your requirements
    };


    return (
        <React.Fragment>
            {isLoading && (
                <div className="w-full h-screen flex justify-center items-center">
                    <ClimbingBoxLoader
                        color={color}
                        size={25}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        className="flex justify-center items-center"
                    />
                </div>
            )}

            <div className="px-3 md:px-10">
                <DashNavbar />
                <div className="grid w-full pt-[3%] md:pt-[1%]">
                    <main className="bg-[#FAFAFc] rounded-2xl flex-1 overflow-y-auto px-5 py-1 w-full">
                        <div className="w-full border-b-2 border-gray-300 pb-3 flex items-center justify-between">
                            <Link onClick={() => navigate(-1)} className="flex items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    fill="currentColor"
                                    className="bi bi-chevron-left text-black"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                                    />
                                </svg>
                                <p className="text-black ml-3 capitalize py-4 text-xl">All Tasks</p>
                            </Link>

                            {/* <Link className="flex items-center justify-center py-2.5 px-4 my-4 rounded-lg transition duration-200 bg-red-900 hover:bg-red-700 text-white">
<p className="font-normal text-sm px-4 capitalize">
Delete selected
</p>
</Link> */}
                        </div>

                        <div className="py-5">
                            {/* nav */}
                            <div className="w-full flex md:justify-center bg-[#F5F5F5] items-center">
                                <div className="w-full md:w-[55%] py-4 flex justify-center items-center">
                                    <p className="text-center text-blue-700 text-[17px] font-medium">List Active Users on Task
                                        <span className="mx-2 bg-lime-600 font-light py-1 px-2 text-white rounded-lg">{singleAdvert?.NumberApproved} Completed</span>
                                        <span className="mx-1 bg-gray-800 font-light py-1 px-2 text-white rounded-lg">{singleAdvert?.TotalNumber} Unrerviewed
                                        </span>

                                    </p>



                                    {/* Display platform information */}
                                    {/* Display platform Div */}
                                </div>
                            </div>
                            <center>

                                <div className="mt-4 text-black">


                                    <>
                                        {singleAdvert?.PerformedTask &&
                                            singleAdvert?.PerformedTask?.length > 0 ? (
                                            <>
                                                {singleAdvert?.PerformedTask?.map((task, index) => (
                                                    <div
                                                        key={index}
                                                        className="mt-2 p-4 bg-gray-200 rounded-lg"
                                                    >
                                                        {/* Display accordion data for the selected platform */}
                                                        <div className="flex flex-col lg:justify-between md:justify-between sm:justify-between justify-center  lg:flex-row md:flex-row    items-center">
                                                            <div className=" text-xs mx-2">
                                                                <p>{formatDate(task.createdAt)}</p>
                                                                <p className="text-xs max-w-sm mt-2 bg-white p-1 rounded-lg">
                                                                    {task.Username}
                                                                </p>
                                                            </div>
                                                            <div
                                                                className=""
                                                                onClick={() =>
                                                                    document.getElementById("my_modal_1").showModal()
                                                                    // console.log('hxxdfgh')
                                                                }
                                                            >
                                                                <img
                                                                    src={task.ImageProof}
                                                                    className="w-52 h-24 object-cover bg-gray-300 p-1 rounded-lg"
                                                                    alt=""
                                                                />
                                                                <dialog
                                                                    id="my_modal_1"
                                                                    className="modal flex justify-center items-center"
                                                                >
                                                                    <div className="modal-box flex flex-col justify-center items-center">
                                                                        <img
                                                                            src={task.ImageProof}
                                                                            className="w-full object-cover bg-gray-300 p-1 rounded-lg"
                                                                            alt=""
                                                                        />
                                                                        <div className="modal-action flex justify-center items-center">
                                                                            <form method="dialog">
                                                                                {/* if there is a button in form, it will close the modal */}
                                                                                <button className="btn">Close</button>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </dialog>
                                                            </div>
                                                            <div className="flex flex-row lg:m-0 md:m-0 my-2">
                                                                {task.Approved === true ? (
                                                                    <>
                                                                        <p className="text-green-300 text-xl">Approved</p>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <button
                                                                            onClick={() =>
                                                                                handleApproveTask(task.TaskId, task._id)
                                                                            }
                                                                            className="btn btn-primary mr-2"
                                                                        >
                                                                            Approve
                                                                        </button>
                                                                        <button
                                                                            onClick={() =>
                                                                                handleDeclineTask(task.TaskId, task._id)
                                                                            }
                                                                            className="btn btn-error ml-2"
                                                                        >
                                                                            Decline
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <p className="text-2x p-6 pl-14 ">
                                                <span className=" shadow-2xl bg-gray-200 rounded-xl text-gray-600 p-3">
                                                    No performed task
                                                </span>
                                            </p>
                                        )}
                                    </>

                                </div>

                            </center>

                        </div>
                    </main>
                </div>
            </div>
            {/* {!isLoading && (
                <React.Fragment>
                    {singleAdvert && singleAdvert.length > null ? (
                        
                    )
                </React.Fragment>

            )} */}

        </React.Fragment>

    );
};

export default TaskDelivery;
