import React from 'react';
import VisitorHeader from '../component/vistorsheader';
import Footer from "../component/footer";

const Terms = () => {
  return (
    <div>
      <VisitorHeader />
      <div className='p-4 lg:p-10 mt-20 max-w-5xl mx-auto bg-white shadow-lg rounded-lg]'>
        <h2 className="text-3xl lg:text-4xl font-medium text-[#2d2d2d] mb-6 text-center">Terms Of Use:</h2>
        <p className='my-2'><strong className="text-lg">Effective Date:</strong> 20th January 2024</p>
        <p className="text-lg">Welcome to Buzzy.ng! By accessing or using our social media marketing app, you agree to comply with and be bound by the following Terms of Use. Please read these terms carefully.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">1. Acceptance of Terms:</h3>
        <p className="text-lg">By using Buzzy.ng, you agree to these Terms of Use, including any updates or modifications. If you do not agree with these terms, please do not use the app.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">2. User Eligibility:</h3>
        <p className="text-lg">You must be at least 18 years old to use Buzzy.ng. By using the app, you represent warrant that you are at least 18 years old.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">3. User Accounts:</h3>
        <p className="text-lg"><strong className="text-lg">a. Account Creation:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">To use certain features of Buzzy.ng, you may need to create an account. You agree to provide acccurate, current and complete information during the registration process.</li>
        </ul>
        <p className="text-lg"><strong className="text-lg">b. Account Security:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account, Notify us immediately of any unauthorized use or security breach.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">4. User Conduct:</h3>
        <p className="text-lg"><strong className="text-lg">a. Prohibited Activities:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">You agree not to engage in any activities that violate the law, infringe on the rights of others, or disrupt the functionality of Buzzy.ng. Prohibited activities include but are not limited to spamming, hacking, or distributing malicious software.</li>
        </ul>
        <p className="text-lg"><strong className="text-lg">b. Content Standards:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">You are responsible for the content you create, upload, or share on Buzzy.ng. Content must not violate any laws, infringe on intellectual property rights, or contain offensive material.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">5. Privacy Policy:</h3>
        <p className="text-lg">By using Buzzy.ng, you agree to the terms outlined in our Privacy Policy regarding the collection, use, and sharing of your personal information.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">6. Intellectual Property:</h3>
        <p className="text-lg"><strong className="text-lg">a. Ownership:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">Buzzy.ng and its content are protected by copyright, trademark, and other intellectual property laws. All rights are reserved</li>
        </ul>
        <p className="text-lg"><strong className="text-lg">b. User Content:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">By sharing content on Buzzy.ng, you grant us a non-exclusive, royalty-free, transferable, sublicensable license to use, reproduce, modify, and distribute your content.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">7. Termination:</h3>
        <p className="text-lg">We reserve the right to terminate or suspend your account and access to Buzzy.ng at our discretion, without notice, for any reason, including if you violate these Terms of Use.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">8. Disclaimer of warranties:</h3>
        <p className="text-lg">Buzzy.ng is provided "as is" and "as available" without any warranties, expressed or implied. We do not guarantee the accuracy, completeness, or reliability of the app.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">9. Limitation of Liabilities:</h3>
        <p className="text-lg">Buzzy.ng and its affiliates will not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the app.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">10. Governing Law:</h3>
        <p className="text-lg">These Terms of Use are governed by and construed in accordance with the laws of Federal Republic of Nigeria.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">11. Update to Terms:</h3>
        <p className="text-lg">We may update these Terms of Use from time to time. You are responsible for regularly reviewing the terms. Continued use of Buzzy.ng after changes constitutes acceptance of the modified terms.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">Contact Information:</h3>
        <p className="text-lg">If you have questions about these Terms of Use, please contact us at <a href="mailto:support@buzzy.ng">support@buzzy.ng</a></p>

        <p className="text-lg mt-4">Thank you for choosing Buzzy.ng!</p>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
