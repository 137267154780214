import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMyContext } from "../context/MyContext";

const SignUpForm2 = ({ regMail }) => {
  const { email, isLoading, resendVerificationEmail, setEmail, setIsLoading } =
    useMyContext();
  const [otp, setOtp] = useState(["", "", "", ""]);

  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve email from local storage
    const storedData = JSON.parse(localStorage.getItem("signupForm1State"));
    if (storedData && storedData.email) {
      setEmail(storedData.email);
    }
  }, []);

  const handleVerifyEmail = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register/otp?email=${regMail}`,
        {
          Otp: otp.join(""),
        }
      );

      const data = response.data;

      if (data.Access) {
        if (data.Verified) {
          toast.success("Email verified successfully");
          navigate(`/register3/${regMail}`);
        } else {
          toast.error("Verification failed: Email not verified");
        }
      } else {
        toast.error(data.Error || "Unknown error");
      }
    } catch (error) {
      toast.error(error.response?.data?.Error || "Unknown error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        return newOtp;
      });

      // Move cursor to the next input
      if (index < otp.length - 1 && value !== "") {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && !otp[index]) {
      // Move cursor to the previous input on Backspace
      document.getElementById(`otp-input-${index - 1}`).focus();
    } else if (event.key === "ArrowRight" && index < otp.length - 1) {
      // Move cursor to the next input on ArrowRight
      document.getElementById(`otp-input-${index + 1}`).focus();
    } else if (event.key === "ArrowLeft" && index > 0) {
      // Move cursor to the previous input on ArrowLeft
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  return (
    <div className="max-w-sm w-full mt-8">
      <h2 className="text-3xl font-extrabold text-black">Verify your Email</h2>
      <p className="mt-2 text-sm text-gray-600">
        To ensure the security of your BUZZY account, we've sent a verification
        code to {regMail}.
      </p>
      <p className="mt-2 text-sm login-text">
        Please check your inbox and enter the 4-digit code below to complete the
        verification process.
      </p>

      <div className="flex my-10 justify-evenly">
        {otp.map((value, index) => (
          <input
            key={index}
            type="text"
            id={`otp-input-${index}`}
            className="otp-input focus:outline-none focus:border-blue-800"
            maxLength="1"
            value={value}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
          />
        ))}
      </div>

      <p className="my-4 text-sm text-center">
        Didn’t receive the email?{" "}
        <span className="login-text" onClick={resendVerificationEmail}>
          Resend
        </span>
      </p>

      <div className="mt-6">
        <button
          type="button"
          onClick={handleVerifyEmail}
          className="w-full py-2 px-4 border rounded-md text-white hover:bg-blue-800 focus:outline-none focus:border-blue-700"
        >
          Verify Email
        </button>
      </div>

      <div className="mt-2">
        <button
          type="button"
          onClick={() => {
            navigate("/register");
          }}
          className="w-full py-2 px-4 rounded-md text-blue-800 hover:text-blue-500 focus:outline-none"
        >
          Change email address
        </button>
      </div>
    </div>
  );
};

export default SignUpForm2;
